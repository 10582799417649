import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Grid, Typography, Button } from '@material-ui/core';
import Filter from '../LiveConnect/filter';
import Workout from '../../hoc/WorkoutContainer';
import Spinner from "../../components/spinner/spinner";
import Routes from '../../components/Router/Routes'
import { Translate } from '../../utils/Translate';
import InfiniteScroll from "react-infinite-scroller";
import LoadingClassesShimmer from "../../components/Shimmer/LoadingClassesShimmer";
import { LAZY_LOADING_PAGE_SIZE } from '../../utils/constants';
import { isSeamlessMode, getTranslatedFile } from '../../utils/utilityfunctions';
import { useCategories } from "../../hooks/useCategories";
// import { FirebaseApp } from '../../FirebaseApp';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Categories = (props) => {
  const { useCategoriesState, useCategoriesDispatch } = useCategories();
  const { metadata, selectedFilters, tempFilterSearchClasses,
          loading, filteredClassSearchData, filteredSkill,
          filteredDuration, filteredKeywords, filteredSubCategory,
          filteredFocusArea,filteredEquipments,filterSortBy, 
          filterSearchText, filteredIntensity, filteredLanguage, 
          filteredTopKeywords,pageCount,paginatedData,
          classesSearchData,tempClassSearchData,topKeywords,
          searchedOnDemandClassesObj, isAuthenticated} = useCategoriesState();
    
  const { sendCategoryDetailsToLocalytics, searchOnDemandClass, setPageCount,
          setPaginatedData,setTempClassSearchData,setFilteredClassSearchData,
          setFilteredTopKeywords, setClassesSearchData, setTopKeywords,
          setKeywords,setSelectedFilters,resetFilters,searchOnDemandClassWithFilter } = useCategoriesDispatch();

  const [categories, setCategories] = useState([])
  const [classesCount, setClassesCount] = useState('')
  const [categoryId, setCategoryId] = useState(null)
  const [categoryName, setCategoryName] = useState('')
  const [categoryDesc, setCategoryDesc] = useState('')
  const [showNoDataFound, setShowNoDataFound] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false);
  const [loadMoreAllCalled, setLoadMoreAllCalled] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /* useEffect(()=>{
    // const firebaseApp = new FirebaseApp();
    // firebaseApp.setUp("categories_page");
    // firebaseApp.setAttribute({key:"categoriesPage",value:"A"});
    
    // firebaseApp.traceData.start();
    return () => {
      // Anything in here is fired on component unmount.
    // firebaseApp.traceData.stop();
  }
  },[]) */

  useEffect(() => {
    if (metadata) {
      setCategoryDetailsFromMetaData(metadata, categoryId)
      if (metadata?.categories&&metadata.categories.length>0) {
        const language = getTranslatedFile()
        let sortedCategories=metadata.categories.sort((a,b)=>language.categoryName[a?.id]?.localeCompare(language.categoryName[b?.id]))
        setCategories(sortedCategories)      
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata])

  const setCategoryDetailsFromMetaData = (metadata, id) => {
    for (let index = 0; index < metadata.categories.length; index++) {
      if (metadata.categories[index].id.toString() === id) {
        setCategoryName(metadata.categories[index].name)
        setCategoryDesc(metadata.categories[index].description)
        sendCategoryDetailsToLocalytics(metadata.categories[index].name, id)
        break;
      }
    }
  }

  useEffect(() => {
    const id = props.match.params.id
    setCategoryId(id)
    if (metadata) {
      setCategoryDetailsFromMetaData(metadata, id)
    }
    fetchData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id])

  const fetchData = (searchType, keyword = '') => {
    setCategoryId(searchType)
    if (selectedFilters.length > 0 || filterSearchText.length > 0)
      fetchDataByFilters(selectedFilters, true)
    else if (pageCount === 0) {
      searchOnDemandClass(searchType, keyword)
    }
  }

  const fetchDataByFilters = (filters, fromNavigation = false, reset = false,fromTopKeyWords=false) => {
    setPageCount(0)
    setPaginatedData([])
    if (reset === true || (filterSearchText.length === 0 && filters.length === 0)) {
      resetAllFiltersValues()
    }
    else if (filterSearchText.length > 0) {
      fetchDataByFilterSearch(filterSearchText)
    }
    else {
      if (fromNavigation)
        getFilteredData(filteredClassSearchData, filters,fromTopKeyWords)
      else
        getFilteredData(classesSearchData, filters,fromTopKeyWords)
    }
  }

  const getFilteredData = (classesSearchData, filters,fromTopKeyWords) => {
    let tempData = [], skillLevels = [], intensityLevels = [], equipment = '', keyword = '', focusArea = '', classLanguage = '', sortBy = '', subCategory = '', duration = ''
    for (let j = 0; j < filters.length; j++) {
      switch (filters[j].filterType.toString().toLowerCase()) {
        case `keywords`:
          keyword = filters[j].filterValue
          break;
        case `duration`:
          duration = filters[j].filterValue
          break;
        case `skill level`:
          skillLevels = filters[j].filterValue.split(',');
          break;
        case `focus area`:
          focusArea = filters[j].filterValue
          break;
        case `equipment`:
          equipment = filters[j].filterValue
          break;
        case `intensity`:
          intensityLevels = filters[j].filterValue.split(',');
          break;
        case `class language`:
          classLanguage = filters[j].filterValue;
          break;
        case `sub category`:
          subCategory = filters[j].filterValue;
          break;
        case `sort`:
          sortBy = filters[j].filterValue;
          break;
        default :
         return null
      }
    }


    for (let i = 0; i < classesSearchData.length; i++) {
      let allow = true, found = true
      //Keywords
      if (keyword.length > 0 && classesSearchData[i].virtualClass.labels && classesSearchData[i].virtualClass.labels.length > 0) {
        for (let index = 0; index < classesSearchData[i].virtualClass.labels.length; index++) {
          if (!(classesSearchData[i].virtualClass.labels[index].includes(keyword))) {
            found = false
          }
          else {
            found = true
            break;
          }
        }
        if (!found)
          allow = false
      }
      else if (keyword.length > 0 && classesSearchData[i].virtualClass.labels && classesSearchData[i].virtualClass.labels.length === 0) {
        allow = false
      }
      else if (keyword.length > 0 && !classesSearchData[i].virtualClass.labels) {
        allow = false
      }
      //Duration
      if (duration.length > 0 && classesSearchData[i].virtualClass.durationSecond) {
        let timeMin, timeMax;
        switch (duration) {
          case '0,600':  //10 min
            timeMin = 0; timeMax = 600
            break;
          case '601,1200':  //20 min
            timeMin = 601; timeMax = 1200
            break;
          case '1201,1800':   //30 min
            timeMin = 1201; timeMax = 1800
            break;
          case '1801,18000':    //40+min
            timeMin = 1801; timeMax = 18000
            break;
          default :
            return null
        }
        if (!(parseFloat(classesSearchData[i].virtualClass.durationSecond) >= parseInt(timeMin) && parseFloat(classesSearchData[i].virtualClass.durationSecond) <= (timeMax))) {
          allow = false
        }
      }
      else if (duration.length > 0 && !classesSearchData[i].virtualClass.durationSecond) {
        allow = false
      }
      //Skill Levels
      if (skillLevels.length > 0 && classesSearchData[i].virtualClass.skill !== undefined) {
        if (!(classesSearchData[i].virtualClass.skill >= skillLevels[0] && classesSearchData[i].virtualClass.skill <= skillLevels[1])) {
          allow = false
        }
      }
      else if (skillLevels.length > 0 && classesSearchData[i].virtualClass.skill === undefined) {
        allow = false
      }
      //Intensity levels
      if (intensityLevels.length > 0 && classesSearchData[i].virtualClass.intensity !== undefined) {
        if (!(classesSearchData[i].virtualClass.intensity >= intensityLevels[0] && classesSearchData[i].virtualClass.intensity <= intensityLevels[1])) {
          allow = false
        }
      }
      else if (intensityLevels.length > 0 && classesSearchData[i].virtualClass.intensity === undefined) {
        allow = false
      }
      //Class SubCategory
      if (subCategory.length > 0 && classesSearchData[i].virtualClass.classSubCategory) {
        if (!(classesSearchData[i].virtualClass.classSubCategory.toLowerCase() === subCategory.toLowerCase())) {
          allow = false
        }
      }
      else if (subCategory.length > 0 && !classesSearchData[i].virtualClass.classSubCategory) {
        allow = false
      }
      //Class Language
      if (classLanguage.length > 0 && classesSearchData[i].virtualClass.languageName) {
        if (!(classesSearchData[i].virtualClass.languageName.toLowerCase() === classLanguage.toLowerCase())) {
          allow = false
        }
      }
      else if (classLanguage.length > 0 && !classesSearchData[i].virtualClass.languageName) {
        allow = false
      }
      //FocusArea
      if (focusArea.length > 0 && classesSearchData[i].virtualClass.focusArea && classesSearchData[i].virtualClass.focusArea.length > 0) {
        for (let index = 0; index < classesSearchData[i].virtualClass.focusArea.length; index++) {
          if (!(classesSearchData[i].virtualClass.focusArea[index].includes(focusArea))) {
            found = false
          }
          else {
            found = true
            break;
          }
        }
        if (!found)
          allow = false
      }
      else if (focusArea.length > 0 && classesSearchData[i].virtualClass.focusArea && classesSearchData[i].virtualClass.focusArea.length === 0) {
        allow = false
      }
      else if (focusArea.length > 0 && !classesSearchData[i].virtualClass.focusArea) {
        allow = false
      }
      //Equipments
      if (equipment.length > 0 && classesSearchData[i].virtualClass.equipmentNames && classesSearchData[i].virtualClass.equipmentNames.length > 0) {
        for (let index = 0; index < classesSearchData[i].virtualClass.equipmentNames.length; index++) {
          if (!(classesSearchData[i].virtualClass.equipmentNames[index].includes(equipment))) {
            found = false
          }
          else {
            found = true
            break;
          }
        }
        if (!found)
          allow = false
      }
      else if (equipment.length > 0 && classesSearchData[i].virtualClass.equipmentNames && classesSearchData[i].virtualClass.equipmentNames.length === 0) {
        allow = false
      }
      else if (equipment.length > 0 && !classesSearchData[i].virtualClass.equipmentNames) {
        allow = false
      }

      if (allow)
        tempData.push(classesSearchData[i])
    }
    !fromTopKeyWords && fetchTopKeywords(tempData)
    setClassesCount(tempData.length)
    if (sortBy.length > 0) {
      let sortedData = tempData.sort((a, b) => {
        if (sortBy === "Newest")
          return new Date(b.virtualClass.scheduleDate) - new Date(a.virtualClass.scheduleDate)
        else
          return new Date(a.virtualClass.scheduleDate) - new Date(b.virtualClass.scheduleDate)
      });
      setTempClassSearchData(sortedData)
    }
    else
      setTempClassSearchData(tempData)

    if (tempData.length === 0)
      setShowNoDataFound(true)
    else
      setShowNoDataFound(false)
    setFilteredClassSearchData(tempData)
    window.scrollTo(0, 0);

  }

  const fetchTopKeywords = (classesSearchData) => {

    let tempTopKeywords = []
    for (let i = 0; i < classesSearchData.length; i++) {
      if (tempTopKeywords.length === 0 && classesSearchData[i].virtualClass.labels.length === 1) {
        tempTopKeywords.push({ keyword: classesSearchData[i].virtualClass.labels[0], count: 1 })
      }
      else {
        if (classesSearchData[i].virtualClass.labels.length === 1) {
          let temp = [...tempTopKeywords], matched = false
          for (let x = 0; x < temp.length; x++) {
            if (tempTopKeywords[x].keyword === classesSearchData[i].virtualClass.labels[0]) {
              tempTopKeywords[x] = { ...tempTopKeywords[x], count: parseInt(tempTopKeywords[x].count) + 1 }
              matched = true
              break;
            }
          }
          if (!matched) {
            tempTopKeywords.push({ keyword: classesSearchData[i].virtualClass.labels[0], count: 1 })
          }
        }
        else {
          let temp = [...tempTopKeywords], matched = false
          for (let j = 0; j < classesSearchData[i].virtualClass.labels.length; j++) {
            matched = false
            for (let y = 0; y < temp.length; y++) {
              if (tempTopKeywords[y].keyword === classesSearchData[i].virtualClass.labels[j]) {
                tempTopKeywords[y] = { ...tempTopKeywords[y], count: parseInt(tempTopKeywords[y].count) + 1 }
                matched = true
                break;
              }
            }
            if (!matched) {
              tempTopKeywords.push({ keyword: classesSearchData[i].virtualClass.labels[j], count: 1 })
            }
          }
        }
      }
    }

    let sortTopKeywords = tempTopKeywords.sort(function (a, b) {
      return b.count - a.count
    })
    let sortedTopKeywords = []
    for (let z = 0; z < sortTopKeywords.length; z++) {
      sortedTopKeywords.push(sortTopKeywords[z].keyword)
    }
    if (sortedTopKeywords.length > 3)
      sortedTopKeywords = sortedTopKeywords.splice(0, 3)

    let temp = [...topKeywords]
    let prevTopKeywords = filteredTopKeywords.length > 0 ? filteredTopKeywords : temp.splice(0, 3)
    let tempsortedTopKeywords = [...sortedTopKeywords]
    let tempprevTopKeywords = [...prevTopKeywords]
    let sortedTKeywordsByWord = tempsortedTopKeywords.sort()
    let sortedPrevTopKeywordsByWord = tempprevTopKeywords.sort()
    // eslint-disable-next-line eqeqeq
    if (JSON.stringify(sortedTKeywordsByWord) != JSON.stringify(sortedPrevTopKeywordsByWord)) {
      setFilteredTopKeywords(sortedTopKeywords)
    }
  }

  useEffect(() => {
    if (searchedOnDemandClassesObj) {
      setClassesSearchData(searchedOnDemandClassesObj.items)
    }
    if (searchedOnDemandClassesObj) {
      if (selectedFilters.length === 0)
        setClassesCount(searchedOnDemandClassesObj.items.length)
      setTopKeywords(searchedOnDemandClassesObj.metaData, searchedOnDemandClassesObj.metaData.topLabels)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedOnDemandClassesObj])

  useEffect(() => {
    if (tempFilterSearchClasses && filterSearchText.length > 0) {
      setClassesCount(tempFilterSearchClasses.items.length)
      setTempClassSearchData(tempFilterSearchClasses.items)
      fetchTopKeywords(tempFilterSearchClasses.items)
      if (tempFilterSearchClasses.items.length === 0)
        setShowNoDataFound(true)
      else
        setShowNoDataFound(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFilterSearchClasses])

  const fetchDataByTopKeywords = (filterValue, filterType = 'Keywords',formTopKeyWords=false) => {
    setPageCount(0)
    setPaginatedData([])
    let tempFilters = [...selectedFilters], sameValueSelected = false
    if (selectedFilters.length === 0) {
      tempFilters.push({ filterType, filterValue })
      setKeywords(filterValue)
    }
    else {
      for (let index = 0; index < selectedFilters.length; index++) {
        if (selectedFilters[index].filterType === filterType) {
          if (selectedFilters[index].filterValue === filterValue)
            sameValueSelected = true
          tempFilters.splice(index, 1);
        }
      }
      if (!sameValueSelected) {
        tempFilters.push({ filterType, filterValue })
        setKeywords(filterValue)
      }
      else {
        setKeywords('')
      }
    }
    setSelectedFilters(tempFilters)
    fetchDataByFilters(tempFilters,false,false,formTopKeyWords)
  }

  const resetAllFiltersValues = () => {
    setClassesCount(classesSearchData.length)
    resetFilters();
    if (classesSearchData.length > 0)
      setShowNoDataFound(false)
  }

  const fetchDataByFilterSearch = (searchText, fromSearch = true) => {
    searchOnDemandClassWithFilter(props.match.params.id, filteredKeywords, filteredFocusArea, filteredSkill, filteredEquipments, filteredDuration, filteredIntensity, searchText, filterSortBy, filteredLanguage, filteredSubCategory, fromSearch);
  }

  const onClickHandler = data => {
    props.history.push({
      pathname: Routes.onDemandClassPlayerRoute + data.tag,
      state: { selectedItem: data },
    });
  };

  window.onscroll = () => {
    if(isSeamlessMode()){
      if (document.querySelector(".staticBlockEw")) {
          if (window.scrollY > 96) {
              document.querySelector(".staticBlockEw").className = "staticBlockEw scroll";
          } else {
              document.querySelector(".staticBlockEw").className = "staticBlockEw";
          }
      }
  }
  else{
      if (document.querySelector(".staticBlock")) {
          if (window.scrollY > 96) {
              document.querySelector(".staticBlock").className = "staticBlock scroll";
          } else {
              document.querySelector(".staticBlock").className = "staticBlock";
          }
      }
  }  
  }

  const resetFilterData = () => {
    fetchDataByFilters([], false, true)
  }

  const loadMore = () => {

    let toFilterClasses = []
    if (tempClassSearchData.length === 0 && selectedFilters.length === 0) {
      toFilterClasses = classesSearchData
    }
    else
      toFilterClasses = tempClassSearchData
    setTimeout(() => {
      if (!loadMoreAllCalled) {
        setLoadMoreAllCalled(true)
        if (pageCount < toFilterClasses.length) {
          let data = toFilterClasses.slice(0, pageCount + LAZY_LOADING_PAGE_SIZE)
          setPageCount(pageCount + LAZY_LOADING_PAGE_SIZE)
          setPaginatedData(data)
        }
        else {
          setHasMoreData(false)
        }
      }
      else {
        setLoadMoreAllCalled(false)
      }
    }, 1000);
  }

  useEffect(() => {
    if (tempClassSearchData && tempClassSearchData.length === 0 && selectedFilters.length === 0 && classesSearchData && classesSearchData.length > 0 && pageCount === 0) {
      setPaginatedData(classesSearchData.slice(0, LAZY_LOADING_PAGE_SIZE))
    }
    else if (tempClassSearchData && tempClassSearchData.length > 0 && (filterSearchText.length > 0 || selectedFilters.length > 0)) {
      setPaginatedData(tempClassSearchData.slice(0, LAZY_LOADING_PAGE_SIZE))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesSearchData, tempClassSearchData])


  useEffect(() => {
    if ((tempClassSearchData && (( tempClassSearchData.length > 0 || (classesSearchData && classesSearchData.length > 0))) && paginatedData.length > 0)) {
      let allClasses = tempClassSearchData.length > 0 ? tempClassSearchData : classesSearchData
      if (allClasses.length <= paginatedData.length) {
        setHasMoreData(false)
      }
      else {
        setHasMoreData(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedData])

  const checkFavStatus = (favoriteId) => {
    let classes = JSON.parse(JSON.stringify(paginatedData));
    let classIndex = classes.findIndex(particularclass => particularclass.classTag === favoriteId)
    if (classIndex !== -1) {
      classes[classIndex].virtualClass.favourite = !classes[classIndex].virtualClass.favourite
      setPaginatedData(classes)
    }

    if (tempClassSearchData.length === 0 && selectedFilters.length === 0 && classesSearchData.length > 0 && pageCount === 0) {
      let classIndex = classesSearchData.findIndex(particularclass => particularclass.classTag === favoriteId)
      if (classIndex !== -1) {
        classesSearchData[classIndex].virtualClass.favourite = !classesSearchData[classIndex].virtualClass.favourite
      }
    }
    else if (tempClassSearchData.length > 0 && (filterSearchText.length > 0 || selectedFilters.length > 0)) {
      let classIndex = tempClassSearchData.findIndex(particularclass => particularclass.classTag === favoriteId)
      if (classIndex !== -1) {
        tempClassSearchData[classIndex].virtualClass.favourite = !tempClassSearchData[classIndex].virtualClass.favourite
      }
    }
  }

  return (
    <div className="page-container align-left">
      <AppBar position="relative" color="inherit" className="mobileScrollParent ipadScrollParent">
      <div className={isSeamlessMode()?"staticBlockEw":"staticBlock"}>
          <div className="mobileScroll ipadScroll">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              //indicatorColor="none"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{style: {backgroundColor: "white"}}}
              aria-label="scrollable auto tabs example"
              alignitems="left" className="browseCategory flexNoWrap">
              {categories.length > 1 && categories.map((category) =>
                // <Tab label={Translate({ id: `categories.${category.name}` })} {...a11yProps(category.id)}
                <Tab key={Math.random()} label={Translate({ id: `categoryName.${category.id}` })} {...a11yProps(category.id)}
                  onClick={() => {
                  if(category.id.toString()!==categoryId)
                   { 
                    setClassesSearchData([])
                    setTopKeywords([])
                    resetFilters()

                    setShowNoDataFound(false)
                    setHasMoreData(false)
                    props.history.push({ pathname: `${Routes.categories}${category.id}` })
                  }
                  }}
                  // eslint-disable-next-line
                  aria-selected={category.id == categoryId}
                  // eslint-disable-next-line
                  className={`makeTextClickable ${category.id == categoryId ? "dynamiclinks Mui-selected" : ""}`} />
              )}
            </Tabs>
          </div>
          <Typography variant="h1"
            style={{ textAlign: 'center' }}
            className={`m-t-xs-15 m-b-xs-10 ${categories.length > 1 ? "ipadSpace-15 deskSpace-30 hidden-xs title" : ""}`}>
            {/* {categoryName} */}
            {categoryName!==""?Translate({ id: `categoryName.${categoryId}` }):''}
          </Typography>
          <Grid container direction="row" className="description">
            <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
              <Typography className="text-gray padding16 hidden-xs" variant="h6" >
                {/* {categoryDesc} */}
                {categoryDesc!==""?Translate({ id: `categoryDescription.${categoryId}` }):""}

              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" className="m-t-xs-15 topKeySpace">
            <Filter
              showFLLOnDemand={false}
              fetchData={fetchData}
              categoryName={categoryId}
              topKeywords={showNoDataFound ? [] : filteredTopKeywords.length > 0 ? filteredTopKeywords : topKeywords}
              initialFilterMetaData={searchedOnDemandClassesObj && searchedOnDemandClassesObj.metaData}
              fetchDataByFilters={fetchDataByFilters}
              classesCount={classesCount}
              fetchDataByTopKeywords={fetchDataByTopKeywords}
              fetchDataByFilterSearch={fetchDataByFilterSearch}
            />
          </Grid>
        </div>
        {!showNoDataFound &&
          <InfiniteScroll
            pageStart={0}
            datalength={LAZY_LOADING_PAGE_SIZE}
            loadMore={loadMore}
            hasMore={hasMoreData}
            loader={hasMoreData && !loading ? <LoadingClassesShimmer key={Math.random()} /> : null}
          >
          <div className="collectionShimmer m-bt-lg-30 m-t-xs-20">
              {
                loading ? <Spinner backColor="#fff" /> :
                  <Grid container justifyContent="flex-start" spacing={4} className="">
                    {paginatedData.length > 0 && paginatedData.map((item, index) => (
                      <Grid key={index} item lg={4} md={6} sm={12} xs={12} >
                        <Workout
                          isSearchPageCard={false}
                          creationDate={item.virtualClass.scheduleDate}
                          trailerLinkWeb={item.virtualClass.trailerLinkWeb}
                          trailerLinkMobile={item.virtualClass.trailerLinkMobile}
                          url={item.streamingLink}
                          isSliderWorkout={true}
                          history={props.history}
                          showFav={isAuthenticated ? true : false}
                          thumbnail={`${item.virtualClass.imageLink}`}
                          equipmentTypes={item.virtualClass.equipmentTypes}
                          equipmentNames={item.virtualClass.equipmentNames}
                          isFav={item.virtualClass.favourite}
                          title={item.virtualClass.className}
                          duration={item.virtualClass.durationSecond
                            ? Math.floor(item.virtualClass.durationSecond / 60)
                            : 55}
                          description={item.virtualClass.classDescription}
                          show={true}
                          calories={item.virtualClass.calorieBurn}
                          id={item.virtualClass.tag}
                          classCategory={item.virtualClass.classCategory}
                          key={categoryId + index}
                          clicked={() => onClickHandler(item.virtualClass)}
                          collectionName={categoryId}
                          level={item?.virtualClass?.level ? item.virtualClass.level : item?.level }
                          favCallback={() => { checkFavStatus(item.virtualClass.tag) }}
                        />
                      </Grid>
                    ))}
                  </Grid>
              }
            </div>
          </InfiniteScroll>
        }

        {
          showNoDataFound && <Grid container justifyContent="flex-start" spacing="4" >
            <Typography className="text-gray padding16 m-t-55 m-t-xs-32"
              style={{ textAlign: 'center', width: '100%' }}
            >
              {Translate({ id: "filter.NoClassesFound" })}
            </Typography>
            <div className="padding16 m-t-xs-15 m-t-30" style={{ textAlign: 'center', width: '100%' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="button m-t-15 btn-default"
                style={{ height: 48, borderRadius: 35, }}
                onClick={resetFilterData}
              >
                <Typography variant="button" style={{ color: "secondary" }}>
                  {Translate({ id: "filter.ShowAllClasses" })}
                </Typography>
              </Button>
            </div>
          </Grid>
        }

      </AppBar >
    </div >
  );
}



export default withRouter(Categories);
