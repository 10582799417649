

import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function Mq(i=250) {
    return useMediaQuery(`@media (min-width: ${i}px)`);
}
  export function IsMiniMobile() {
      const theme = useTheme();
      return useMediaQuery(theme.breakpoints.up('xs'));
  }
  export function IsMobile() {
      const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up('sm'));
  }
  export function IsTab() {
      const theme = useTheme();
      return useMediaQuery(theme.breakpoints.up('md'));
    }
  export function IsDesktop() {
      const theme = useTheme();
      return useMediaQuery(theme.breakpoints.up('lg'));
  }
export function IsProjector() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up('xl'));
}