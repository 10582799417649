import React, { useEffect, Fragment, useState } from 'react';
import LiveStreamHome from "../LiveStreaming/LiveStreamHome";
import BrowseClasses from "./BrowseClasses";
import { withRouter } from "react-router-dom"
import Routes from '../../components/Router/Routes';
import LiveScheduleShimmer from '../../components/Shimmer/LiveScheduleShimmer';
import BrowseClassesShimmer from "../../components/Shimmer/BrowseClassesShimmer";
import { useDefaultClassesPage } from "../../hooks/useDefaultClassesPage"

const config = require("../../assets/config.json");

function DefaultClassesPage(props) {
    const { useDefaultClassesPageState, useDefaultClassesPageDispatch } = useDefaultClassesPage()
    const { LSHomeClasses, liveConnectLoading,metadata } = useDefaultClassesPageState()
    const { fetchLSConnectHome } = useDefaultClassesPageDispatch()
    const [categories, setCategories] = useState([])

    useEffect(() => {
        if (config.showLiveEvent) {
            fetchLSConnectHome()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (metadata) {
            setCategories(metadata.categories)
        }
    }, [metadata])

    return (
        categories ? categories.length === 1 ?
            <Fragment>
                {props.history.push({ pathname: `${Routes.categories}${categories[0].id}` })}
            </Fragment> :
            <div className="page-container">
                {config.showLiveEvent && liveConnectLoading ? <LiveScheduleShimmer /> : config.showLiveEvent && LSHomeClasses?.data && <LiveStreamHome clubClasses={LSHomeClasses && LSHomeClasses.data} />}
                {liveConnectLoading ? <BrowseClassesShimmer /> : <BrowseClasses />}
            </div> : null
    )
}

export default withRouter(DefaultClassesPage)
