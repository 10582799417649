import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import LiveStreamCard from "./LiveStreamCard";
import { Grid, Typography } from "@material-ui/core";
import { Translate } from '../../utils/Translate';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Routes from "../../components/Router/Routes"
import CustomGrid from '../CustomGrid/CustomGrid';


function LiveStreamHome(props) {
    const {clubClasses=[]} = props;
    if(clubClasses.length)clubClasses.length = 3;
    const viewDetails = () => {
        props.history.push({  
            pathname: Routes.connectLiveSchedule
        });
    };
    return (
        <Fragment>
            <div>
                <div className="left-border m-t-15">
                <Grid container direction="row" alignItems="center" >
                    <Grid item className="align-left makeTextClickable" onClick={viewDetails}>
                        <Typography variant="h1" className="align-left">{Translate({ id: "homePage.LiveScheduleTitle" })} </Typography>
                    </Grid>
                    <Grid item className="makeTextClickable" onClick={viewDetails}>
                    <KeyboardArrowRightIcon height="34" className="iconColor" style={{fontSize:"2.7rem"}}/>
                    </Grid>
                </Grid>
                <Typography className="text-gray align-left" variant="h6" >
                    {Translate({ id: "homePage.LiveScheduleDescription" })}
                </Typography>
                </div>
            </div>
            <div className="m-t-15 m-t-xs-15 m-b-20">
                <div className="live">
                    <div className="mobileScroll">
                        <CustomGrid 
                            items={clubClasses} 
                            spacing={2}
                            showLoading={false}
                            isPageContainer={false}
                            classes="cardList flexNoWrap livstreamHome">
                            {(clubData)=>
                                <LiveStreamCard
                                creationDate={clubData.created_Time}
                                nextOccurence={clubData.next_Occurrence_Time}
                                activeTime={clubData.live.active_Time}
                                eventStatus={clubData.live.status}
                                trailerLinkWeb={clubData.trailerLink}
                                history={props.history}
                                thumbnail={clubData.pictures ? (clubData.pictures.sizes ? clubData.pictures.sizes[0].link : "") : ""}
                                url={clubData.streamingLink}
                                title={clubData.title}
                                duration={clubData.duration
                                    ? Math.floor(clubData.duration / 60)
                                    : clubData.type === 2 ? undefined : 55}
                                durationInSec={clubData.duration}
                                description={clubData.stream_Description}
                                show={true}
                                id={clubData.id} 
                                // clicked={onClubCardClicked}
                                level={clubData.level}
                                isClubConnect={true} 
                                action={viewDetails}
                                videoId={clubData.videoId}
                            />
                            }
                        </CustomGrid>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(LiveStreamHome)

