import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import ClassOfTheComponent from './ClassOfTheDayComponent'
import Routes from '../../Router/Routes'
import config from "../../../assets/config.json"
import logger from "../../../utils/logger"


function Cotd(props) {
    const [classOfTheDay, setClassOfTheDay] = useState(null);
    useEffect(() => {
        if (Object.keys(props.cotdObj).length > 0) {
            if (props.cotdObj.collectionType === "classoftheday" &&
             (props.cotdObj.collectionItems != null) && 
             (props.cotdObj.collectionItems.length > 0)) {
                logger(props.cotdObj);
                setClassOfTheDay(props.cotdObj);
            }
        }

    }, [props.cotdObj]);
    const takeUserToClassDetail = (index) => {
            props.history.push({
                pathname: Routes.onDemandClassPlayerRoute + classOfTheDay.collectionItems[index].tag,
                state: { selectedItem: classOfTheDay.collectionItems[index] },
            });
    };
    return (
        <React.Fragment>
            {config.showClassOfTheDay && classOfTheDay != null &&
                
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <ClassOfTheComponent takeUserToClassDetail={takeUserToClassDetail} classOfTheDay={classOfTheDay} />
                    </Grid>
                
            }

        </React.Fragment>
    )
}

export default Cotd;
