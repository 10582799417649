import React from "react";
import Tc from "../../hoc/TcContainer"
import PageTitle from "../../components/PageTitle";
import { Translate } from "../../utils/Translate";
import "../../theme/styles/tnc.scss";

class TncLink extends React.PureComponent {
  render() {
    return (
      <div className="p-t-62">
        <PageTitle label={Translate({ id: "layout.TermsAndCondition" })} />
        <Tc />
      </div>
    );
  }
}
export default TncLink;
