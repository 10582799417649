import React from "react";
import "../../components/PlayerAndCarousel/Workout/Workout.css";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CountdownButton from "./countdownButton";
import { withRouter } from "react-router-dom";
import { getNextOccurenceDate, getTimetoStart } from '../../utils/LivestreamTime';
import Routes from "../../components/Router/Routes"


function LiveStreamCard(props) {

  const openPlayer = () => {
    props.history.push({
      pathname: `${Routes.liveSchedulePlayerRoute}${props.id}`,
      state: {
        cardDetails:{activeTime: props.activeTime,
        creationDate: props.creationDate,
        description: props.description,
        duration: props.duration,
        eventStatus: props.eventStatus,
        id: props.id,
        level: props.level,
        nextOccurence: props.nextOccurence,
        thumbnail: props.thumbnail,
        title: props.title,
        trailerLinkWeb: props.trailerLinkWeb,
        url: props.url,
        videoId:props.videoId,
        durationInSec:props.durationInSec
      }
      }
    });
  }
  return (
    <Grid container item className="">
      <div className="workoutPlayer">
        <video
          style={{ width: "100%" }}
          preload="none"
          loop
          playsInline
          className="Trailer thumbnail"
          poster={props.thumbnail}
          // onClick={openPlayer}
        //   onMouseOver={(e) => this.startVideo(e.target)}
        //   onMouseOut={e => e.target.pause()}
        >
          {/* <source
            src={props.trailerLinkMobile
              ? props.trailerLinkMobile
              : props.trailerLinkWeb
                ? props.trailerLinkWeb
                : props.url
            }
            type={props.trailerLinkMobile || props.trailerLinkWeb
              ? "video/mp4"
              : "application/x-mpegURL"
            }
          /> */}
        </video>
        <div className="workout-info makeTextClickable" onClick={openPlayer}>
          <Typography variant="subtitle2" className="workout-day">
            {getNextOccurenceDate(props.eventStatus, props.activeTime, props.nextOccurence)}
          </Typography>
          <Typography variant="h2" className="m-t-5 m-t-xs-5">
            {getTimetoStart(props.nextOccurence)}
          </Typography>
          <p className="workout-desc">
            {props.title}
          </p>
          {/* <CountdownButton startTime={props.activeTime} status={props.eventStatus} /> */}
          <CountdownButton startTime={props.activeTime} status={props.eventStatus} clickHandler={openPlayer} />

        </div>
      </div>
    </Grid>
  )
}

export default withRouter(LiveStreamCard)
