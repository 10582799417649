import { Translate } from '../utils/Translate';

const config = require('../assets/config.json')
//const language = getTranslatedFile()//require(`../assets/translations/${config.language}.json`);

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}
export const getTranslatedFile = () => {
  try {
    // check whether language is passed as a parameter incase of embeded mode if not 
    // assigned config language as language
    const URL =
    window.location.search && window.location.search !== ""
   ? window.location.search
   : sessionStorage.getItem("embededConfig");

    if (URL && URL !== "" && URL.includes("runtime=embedded")) {
      const params = new Map(URL.slice(1).split('&').map(kv => kv.split('=')))
      if (params.has('lang')) {
        config["language"] = params.get('lang');
      } 
      else 
    // eslint-disable-next-line no-self-assign
        config["language"] = config.language;
    }
    let languageCode = localStorage.getItem("language") ?? config.language;
    console.log('getTranslatedFile looking content for ', languageCode)
    // eslint-disable-next-line eqeqeq
    let supportedLanguages = config.supportedLanguages
    // eslint-disable-next-line eqeqeq
    if (supportedLanguages == null || supportedLanguages.length == 0) {
      console.log('getTranslatedFile supportedLanguages is null')
      return getJSONFileWithName(languageCode)
    }

    /// exact match
    for (let index = 0; index < supportedLanguages.length; index++) {
     // eslint-disable-next-line eqeqeq
      let obj = supportedLanguages[index]
      // eslint-disable-next-line eqeqeq
      if (obj.toLocaleLowerCase() == languageCode.toLocaleLowerCase()) {
        console.log('getTranslatedFile supportedLanguages have exact match')
        return getJSONFileWithName(languageCode)
      }
    }

    /// check if given language exist in the array
    for (let index = 0; index < supportedLanguages.length; index++) {
      let obj = supportedLanguages[index]
      if (obj.toLocaleLowerCase().includes(languageCode.toLocaleLowerCase())) {
        // console.log('getTranslatedFile supportedLanguages have partial match')
        return getJSONFileWithName(obj)
      }
    }

    if (languageCode.length > 1) {
      let firstTwoChar = languageCode.substring(0, 2);
      if(getJSONFileWithName(firstTwoChar) == null){
        return getJSONFileWithName("en-gb");
      } else
      return getJSONFileWithName(firstTwoChar)
    } 
  } catch (error) {
    console.error('error in getTranslatedFile ', error)
  }
}

const getJSONFileWithName = (name) => {
  let content = null
  config.language = name
  try {
    content = require(`../assets/translations/${name}.json`);
  } catch (error) {
    const defaultLanguageUri = '../assets/translations/en-gb.json'
    const defaultLanguage = require(defaultLanguageUri);
    if(defaultLanguage){
      content = defaultLanguage
    } else console.error('en-gb is not defined')
  }
  return content
}


export const getDurationMins = () => {
  const language = getTranslatedFile()
  const durationMins = [
    { value: language.ClassListViews["10mins"] },
    { value: language.ClassListViews["20mins"] },
    { value: language.ClassListViews["30mins"] },
    { value: language.ClassListViews["plusmins"] }
  ]
  return durationMins
}

export const getDurationInMinsWithLabel = () => {
  const language = getTranslatedFile()
  const durationMins = [
    {
      value: '0,600',
      label: language.ClassListViews["10mins"]
    },
    {
      value: '601,1200',
      label: language.ClassListViews["20mins"]
    },
    {
      value: '1201,1800',
      label: language.ClassListViews["30mins"]
    },
    {
      value: '1801,18000',
      label: language.ClassListViews["plusmins"]
    }
  ]
  return durationMins
}

export const SortValues = [
  { label: Translate({ id: "filter.Newest" }), value: 'Newest' },
  { label: Translate({ id: "filter.Oldest" }), value: 'Oldest' }
]



//compareDurationMins will compare string duration values 
export const compareDurationMins = () => {
  const language = getTranslatedFile()
  return {
    "10mins": language.ClassListViews["10mins"],
    "20mins": language.ClassListViews["20mins"],
    "30mins": language.ClassListViews["30mins"],
    "40mins+": language.ClassListViews["plusmins"],
  }
}

//compareDurationMins will compare string duration values 
export const createStateObj = (data) => {
  let obj = {};

  if (data && data.length > 0) {
    for (let i = data.length; i--;) {
      obj[data[i]["formItems"]["name"]] = ""
    }
  }
  return obj;

}
export const isEmbeddedMode = ()=> !!sessionStorage.getItem("embededConfig");
export const isEmbedded = () => {
  if (config.embeddedConfig && config.embeddedConfig.isEmbedded === true) {
    return true;
  }
  return false;
}

export const isSeamlessMode = () => {
  if (isEmbedded()) {
    const embeddedConfig = config.embeddedConfig
    // eslint-disable-next-line eqeqeq
    return embeddedConfig.showSignInPage == false && embeddedConfig.showSignUpPage == false
  }
  return false
}

export const liveEventStatusForWexer = (curr)=>{
  // type feild for live events
  const isWexer = config.connectSource === "wexer";
  return isWexer && curr?.type === 2 && curr?.eventStatus === "streaming" ? "streaming" : "unavailable"
  // new Date(activeTime).getTime() <= today.getTime()
}

export const setHeaderLanguage = (api)=>{
  try{
  const selectedLanguage = localStorage.getItem("language");
  if(selectedLanguage)
      api.setHeader("Accept-Language", selectedLanguage);
  else
      api.setHeader("Accept-Language", null);
    }
  catch(e){
    api.setHeader("Accept-Language", null);
  }
}

export const checkSubscriptionForEmbeddedOnly = () =>{
 if(isEmbeddedMode()){
 return ( config.isSubscriptionApiCallSkip === true)?false:true;
 }else{
return true
 }
}

export const qsStrigify = (obj)=>{
  try {
    return new URLSearchParams(obj).toString();
  } catch (error) {
    let res = '';
    Object.keys(obj).forEach((key,val) => res+=`${key}=${val}`);
    return res;
  }
}