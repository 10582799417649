import React from "react";
import { withRouter } from "react-router";
import ChannelsCard from "./ChannelsCard";
import Routes from '../../components/Router/Routes'
import { useState, useEffect } from "react";

function ChannelsCardWrapper(props) {

  const { data } = props;
  const [description, setDescription] = useState(null);
  const [displayName, setDisplayName] = useState(null);




  useEffect(() => {

    if (data !== null) {
      let channelDescription = data.description.invariantText;
      let channelDisplayName = data.displayName.invariantText;
      if (channelDescription === undefined && channelDisplayName === undefined) {
        setDescription(data.description);
        setDisplayName(data.displayName)
      }
      else {
        setDisplayName(channelDisplayName)
        setDescription(channelDescription)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewChannel = () => {
    props.history.push({
      pathname: Routes.channelDetailView + data.tag, // RR : Routing to channele detail with channel tag, It will work for deeplink as well
      state: { clubClasses: data },
    });
  };

  return (

    <ChannelsCard
      data={data}
      redirectTo={viewChannel}
      displayName={displayName}
      profileImageUrl={data.profileImageUrl}
      description={description}
      streamDescription={data.title[0].classDescription}
      streamingLink={data.title[0].streamingLink}
      level={data.title[0].level}
      trailerLinkWeb={data.title[0].trailerLinkWeb}
      duration={data.title[0].durationSecond}
      created_Time={data.title[0].scheduleDate}
      thumbnail={data.title[0].imageLink}
      title={data.title[0].className}
      trailerLinkMobile={data.title[0].trailerLinkMobile}
      id={data.title[0].tag}
      isFav={data.title[0].favourite}
      gotoPlayer={() => {
        props.history.push({
          pathname: Routes.onDemandClassPlayerRoute + data.title[0].tag,
          state: { selectedItem: data,from:'CHANNEL' },
        });
        // props.history.push({
        //    pathname: Routes.channelsPlayer + data.tag +"/classes"+"/"+ data.title[0].tag,
        //   state: { selectedItem: data},
        // });
      }}

    />

  );

}

export default withRouter(ChannelsCardWrapper);
