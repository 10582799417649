import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../Logo/Logo";
import AppBar from "@material-ui/core/AppBar";
import AccountButtons from "../../assets/components/AccountButtons";
import LoggedInTexts from "../../assets/components/LoggedInTexts";
import { withStyles } from '@material-ui/core/styles';
import { isSeamlessMode } from "../../utils/utilityfunctions";
const config=require("../../assets/config.json");
const minWidth = '@media (min-width:780px)';
const style = {
  appbar: {
    boxShadow: '0 2px 100px 0 rgba(0, 0, 0, 0.2)',
  },
  headHeight: {
    [minWidth]: {
      height: "96px"
    }
  },
  margin: {
    [minWidth]: {
      marginTop: '16px',
      marginBottom: '16px'
    },
    paddingLeft: "0px",
    paddingRight: "0px",
  }
}


class Appbar extends React.Component {

  render() {
    const { classes } = this.props;
    const isEmbeddedAndOnlyTenant=(config.embeddedConfig &&config.embeddedConfig["content-mode"] &&config.embeddedConfig["content-mode"].toLowerCase() === "tenant")
    return (
     
      <AppBar
        position={isSeamlessMode() ? "sticky" : "static"} color={config.barColor==="#ffffff" ? "white": "primary"}
        className=""
      >
         <div className="page-container full-width">
           <Toolbar className={classes.margin} style={{
          justifyContent: "space-between"
        }} >
          <Logo />
          {this.props.isAuthenticated &&!(isEmbeddedAndOnlyTenant)&&
            <LoggedInTexts history={this.props.history} />
          }

          {!this.props.isAuthenticated &&!(isEmbeddedAndOnlyTenant)&&
            (<div className="login-panel">
              <AccountButtons history={this.props.history} />
            </div>
            )} 
        </Toolbar>        
      </div>
      </AppBar>
    );
  }
}


export default withStyles(style)(Appbar) 
