import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Translate } from "../../utils/Translate";
import { Grid, Typography } from "@material-ui/core";
import Slider from "../../hoc/SliderContainer"
import Routes from '../../components/Router/Routes'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';



function CollectionClasses(props) {
    const [collectionData, setcollectionData] = useState(props.collectionArray)

    useEffect(() => {
        /**
         * Nikhil Gupta
         * CF-9190
         * to show the featured collection under collection tag on home page
         */

        let collectionArray = props.collectionArray
        if (collectionArray) {
            let featuredCollection = collectionArray.find((collection) => {
                return collection.collectionType.toLowerCase() === "featured"
            })
            setcollectionData(featuredCollection)
        }
    }, [props.collectionArray])

    const clickHandler = () => {
        props.history.push(Routes.collections)
    }

    return (
        collectionData && collectionData.collectionItems && collectionData.collectionItems.length > 0 ?
            <Grid item className="mobileScrollParent ">
                <Grid container direction="row" alignItems="center" className="m-t-30 m-t-xs-32">
                    <Grid item className="align-left makeTextClickable adjustTitleWidth" onClick={clickHandler}>
                        <Typography variant="h1" className="align-left clear">
                            {Translate({ id: "homePage.CollectionsTitle" })}
                        </Typography>
                    </Grid>
                    <Grid item className="makeTextClickable" onClick={clickHandler}>
                        <KeyboardArrowRightIcon
                            className="align-bottom makeTextClickable iconColor"
                            style={{ fontSize: "2.7rem" }}
                            onClick={clickHandler}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h6" className="align-left text-gray">
                            {Translate({ id: "homePage.CollectionsDescription" })}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="align-left">
                        <Typography variant="caption" className="dynamiclinks makeTextClickable"
                            onClick={() => {
                                props.history.push({
                                    pathname: Routes.collectionViewRoute + collectionData.collectionTag,
                                    state: { selectedCollection: collectionData.collectionName },
                                })
                            }}>
                            {collectionData.collectionName}
                        </Typography>
                    </Grid>
                </Grid>
                <div className="CollectionHome mobileScroll m-t-8">
                    <Slider
                        key={Math.random()}
                        collectionTag={collectionData.collectionTag}
                        collectionName={collectionData.collectionName}
                        items={collectionData.collectionItems}
                        description={collectionData.description}
                    />
                </div>

            </Grid> : null
    )
}

export default withRouter(CollectionClasses);
