import { initializeApp } from "firebase/app";
import { getPerformance,trace } from "firebase/performance";
class FirebaseApp {
  App;
  perfrence;
  traceData;
  constructor(){
    const firebaseConfig = this.getConfig();
    this.App = initializeApp(firebaseConfig);
  }
  getConfig(){
    try{
      const fireBaseConfig = JSON.parse(process?.env?.REACT_APP_FIREBASE_CONFIG);
      const selectedProject = process?.env?.REACT_APP_FIREBASE_PROJECT ?? 'wexer-test';
      const selectedSite = process?.env?.REACT_APP_FIREBASE_SITE_NAME ?? 'wexer-test';
      const {apiKey,authDomain,databaseURL,projectId,storageBucket,messagingSenderId } = fireBaseConfig[selectedProject];
      return { apiKey,authDomain,databaseURL,projectId,storageBucket,messagingSenderId , ...fireBaseConfig[selectedProject][selectedSite]};
    }
    catch(e){
      return {  
                apiKey: "AIzaSyCQkWtqt5GWzOEgCczQlfsY3YTQXmy9cBM",
                authDomain: "wexer-test.firebaseapp.com",
                databaseURL: "https://wexer-test.firebaseio.com",
                projectId: "wexer-test",
                storageBucket: "wexer-test.appspot.com",
                messagingSenderId: "448322517605",
                appId: "1:448322517605:web:6f7894e7786e50a258e48c",
                measurementId: "G-8BEXJYNZ0V"
              }
    }
  }
  defaultTrace(){
    try{
      this.perfrence = getPerformance();
      this.traceData = trace(this.perfrence);
    }
    catch(e){

    }
  }
  setUp(pageName = "home"){
    this.perfrence = getPerformance();
    this.traceData = trace(this.perfrence,pageName);
  }
  getDetails(){
    return {perfrence:this.perfrence,traceData:this.traceData};
  }
  setAttribute({key,value}){
    this.traceData.putAttribute(key,value);
  }
  getAttribute(key){
    this.traceData.gutAttribute(key);
  }
  getAttributes(){
    this.traceData.getAttributes();
  }
  delAttribute(key){
    this.traceData.removeAttribute(key);
  }
}


export {FirebaseApp};