import React from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { Grid } from "@material-ui/core";
import LoadingClassesShimmer from "../../components/Shimmer/LoadingClassesShimmer";
const CustomGrid = (props) => {
  const {
    children,
    items,
    totalItems,
    hasMore,
    showLoading,
    loadMore,
    classes,
    containerClass,
    loader,
    isError,
    spacing,
    itemClass,
    isPageContainer
  } = props;
  return (
    <div className={isPageContainer ? `page-container ${containerClass}` : `${containerClass}`}>
      {/* <Filter paginatedDataOnDemand={paginatedDataOnDemand} /> */}
      <InfiniteScroll
        datalength={totalItems || items.length}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={loader}
      >
        <Grid container justifyContent="flex-start" spacing={spacing} className={classes}>
          {isError &&  children}
          {showLoading ? (
            <LoadingClassesShimmer
              myClass="live-stream-loader"
              totalCards={totalItems || items.length}
            />
          ) : (children instanceof Function)&&items.map((item, index) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={index} className={itemClass}>
              {children(item,index)}
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};
CustomGrid.defaultProps = {
    loader : null ,
    containerClass : '' ,
    itemClass:'collection',
    items : [],
    isPageContainer:true,
    showLoading:true,
    isError:false,
    hasMore:false,
    spacing:4,
    loadMore:()=>null
};
CustomGrid.propTypes = {
  items: PropTypes.array.isRequired,
  spacing: PropTypes.number,
  hasMore: PropTypes.bool,
  showLoading: PropTypes.bool,
  isPageContainer: PropTypes.bool,
  isError: PropTypes.bool,
  loadMore: PropTypes.func,
  classes: PropTypes.string,
  itemClass: PropTypes.string,
  containerClass: PropTypes.string,
  loader: PropTypes.oneOfType([PropTypes.func,PropTypes.number]),
  totalItems: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
};

export default CustomGrid;
