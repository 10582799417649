import React from "react";


import Grid from "@material-ui/core/Grid";
import {  Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import ChannelsCardWrapper from "./ChannelsCardWarpper";
import Spinner from "../../components/spinner/spinner";
import CustomGrid from "../CustomGrid/CustomGrid";

class ChannelsCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: [],
      isloading: true,
    };
  }

  componentDidMount() {
   this.handelMount();
   this.props.clearClassData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.allChannels !== this.props.allChannels) {
      this.updateChannelsState();
    }
  }
  handelMount(){
    window.scrollTo(0, 0);
    if (this.props.allChannels === null || this.props.allChannels.length === 0) {
      this.props.fetchAllChannels();
    } else {
      this.setState({ channels: this.props.allChannels,isloading:false });
    }
  }
  updateChannelsState = () => {
    if (this.props.allChannels && this.props.allChannels.length > 0) {
      this.setState({ channels: this.props.allChannels,isloading:false });
    }
  };

  ViewChannel = () => {
    this.props.history.push({
      pathname: "/channelView",
      state: { clubClasses: this.props.allChannels },
    });
  };
  render() {
    let {isloading} =this.state;
    return (
      <div>
        <Grid container>
          <div className="fixedWidthContainer container channelbg-banner">
                    {
                        <div className="bg-placeholder">
                            <div className="fallbackBox" />
                            <img src={require("../../assets/images/channel-banner.jpg")} className="bg-banner" alt="background" />
                        </div>
                    }

                    <div className="overlay banner-overlay text-center">
                        <div style={{ zIndex: 1 }} className="centeredText text-white">
                            <Typography variant="h1" className="m-t-xs-5">
                            {Translate({ id: "homePage.ChannelsTitle" })}
                            </Typography>
                            <Grid container direction="row">
                                <Grid item lg={12} md={12} className="text-center channelDescription hidden-xs">
                                    <Typography className="padding16" variant="h6" >
                                    {Translate({ id: "homePage.ChannelsDescription" })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
          </Grid>
          <CustomGrid
            items={this.state.channels}
            totalItems={this.state.channels.length}
            showLoading={isloading}
            classes={isloading ? 'cardList' : 'cardList no-hidden'}
            containerClass="m-bt-lg-30 m-t-xs-20 channelSection"
            loader={<Spinner backColor="white" />}
          >
           { (data => <ChannelsCardWrapper data={data} />)}
          </CustomGrid>
      </div>
    );
  }
}

export default ChannelsCollection;
