import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";

const CollectionClassesShimmer = (props) => {
    return (
        <Grid item lg={12} xs={12} sm={12} md={12}>
            <div className="collectionShimmer mobileScroll m-t-30 m-b-xs-10">
                <Skeleton variant="text" width={'30%'} height={25} />
                <Skeleton variant="text" width={'40%'} height={15} />
                <Skeleton variant="text" width={'30%'} height={13} />
                <Grid container justifyContent="flex-start" spacing={4} className="cardList flexNoWrap collectionShimmerCards">
                    {[1, 2, 3].map(() =>
                        <Grid key={Math.random()} item lg={4} md={6} sm={12} xs={12} >
                            <Skeleton variant="rect" height={213} className="Trailer thumbnail cardBorder" />
                            <Skeleton variant="text" width={'30%'} height={20} />
                            <Skeleton variant="text" width={'50%'} height={15} />
                            <Skeleton variant="text" width={'30%'} height={17} />
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    );
}
export default CollectionClassesShimmer;
