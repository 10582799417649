import * as actionTypes from "./actionTypes";
import api from "../../api";
import * as APIConstants from "../APIConstants";
import userApi from "../../userAPI";
import { Translate } from "../../utils/Translate";
import logger from "../../utils/logger"
import { compareDurationMins, liveEventStatusForWexer, setHeaderLanguage } from '../../utils/utilityfunctions';
import { isEmpty,isEqual } from "lodash";
import { setTenantConfigEventTime } from "./webPlayer"
// import {dummyResponse} from "./dummyResponse"

const config = require("../../assets/config.json")

const findLanguage = () => {
  if(config.embeddedConfig && config.embeddedConfig.isEmbedded){
    const URL =
       window.location.search && window.location.search !== ""
      ? window.location.search
      : sessionStorage.getItem("embededConfig");
      
      if (URL && URL !== "" && URL.includes("runtime=embedded")) {
        const params = new Map(URL.slice(1).split('&').map(kv => kv.split('=')))
        if (params.has('lang')) {
          return params.get('lang');
        } 
        else 
          return config.language;
  } 
}
  else 
  return config.language;
}

var moment = require('moment');
require("moment/min/locales.min");
moment.locale(findLanguage());

//const FETCH_LIVESTREAM_SELECTED_DATE
export const saveSelectedDate = date => {
  return { type: actionTypes.SAVE_SELECTED_DATE, date }
};

//const FETCH_ONDEMAND_CLASSES
export const savePrevPlayer = data => {
  return dispatch => {
    logger("player saved")
    dispatch({ type: actionTypes.SAVE_PREV_PLAYER, player: data });
  };
};

export const getCollectionById = () => {
  return dispatch => {
    logger(localStorage.getItem('userId'));
    let userID = localStorage.getItem('userId');

    if (userID !== undefined || userID !== null || userID !== "") {
      dispatch(fetchOnDemandClasses(userID));
    } else {
      dispatch(fetchOnDemandClasses())
    }

    // api.setHeader("Authorization","Bearer");
    // let url = APIConstants.collectionByTagAPI + id;

    // if (userId !== undefined || userId !== null || userId !== "") {
    //   url = url + "/" + userId;
    // }
    // dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_COLLECTION_START });
    // api.get(url).then(response => {
    //   if (response.ok) {
    //     logger(response)
    //     dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_COLLECTION_SUCCESS, collection: response.data[0] });
    //   } else {
    //     dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_COLLECTION_FAIL });
    //   }
    // });
  };
};
export const fetchTenantConfig = () => {
  logger("fetchTenantConfig")
  return dispatch => {
    const configStartTime = new Date().getTime();
    dispatch({ type: actionTypes.TENENT_CONFIG_LOADING_START });
    // api.setHeader("Authorization","Bearer");
    let url = APIConstants.getTenantConfigAPI + config.name;

    api.get(url).then(response => {
      if (response.ok) {
        /**
         * RR// July 04, 2020 pc 
         * CF-8288 ||Tenant Config added from API
         * Setting Tenent Config in Runtime Memory
         */
        let newConfig = response.data[0];
        logger(newConfig.showClassOfTheDay);
        config.supportEmail = newConfig.contactEmailAddress;
        config.AccessCodeMandatory = newConfig.isAccessCodeMandatory;
        config.showClassOfTheDay = newConfig.showClassOfTheDay;
        config.ShowDiscountTextFeild = newConfig.showDiscountTextField;
        config.EventCardSize = newConfig.eventCardSize
        config.showChannels = newConfig.showChannels;
        config.connectSource = newConfig.connectSource==="vimeo"?"vimeo":"wexer"
        config.providerId = newConfig.providerId
        if (newConfig.dateFormat!==null || newConfig.dateFormat !== undefined){
          config.dateFormat = newConfig.dateFormat
        }
        config.tenantID = newConfig.tenantID
        config.copyright = newConfig.copyright
        config.jwJSPlayerId = newConfig.jwJSPlayerId
        if (newConfig.mmsSignupLink!==null){
          config.mmsSignupLink = newConfig.mmsSignupLink
        }
        if (newConfig.connect) {
          if (newConfig.connect.toLowerCase() === "ondemand") {
            config.showLiveEvent = false;
            config.ShowLiveConnect = true;
          } else if (newConfig.connect.toLowerCase() === "live") {
            config.showLiveEvent = true;
            config.ShowLiveConnect = false;
          } else if (newConfig.connect.toLowerCase() === "both") {
            config.showLiveEvent = true;
            config.ShowLiveConnect = true;
          } else {
            config.showLiveEvent = false;
            config.ShowLiveConnect = false;
          }
        } else {
          config.showLiveEvent = false;
          config.ShowLiveConnect = false;
        }
        dispatch({ type: actionTypes.TENENT_CONFIG_LOADING_SUCCESS, tenantConfig: true });
      } else {
        dispatch({ type: actionTypes.TENENT_CONFIG_LOADING_FAIL, tenantConfig: true });
      }
      const configEndTime = new Date().getTime();
      const time =Math.abs((configStartTime - configEndTime) / 1000);
      dispatch(setTenantConfigEventTime(time));
    });
  };
};

export const fetchOnDemandClasses = userId => {
  logger("making collection api call")
  return dispatch => {
    // dispatch(fetchTenantConfig());
    // api.setHeader("Authorization","Bearer");
    let url = APIConstants.onDemandCollectionAPI;
    if (userId !== null) {
      url = url + "/" + userId;
    } else {
      url = url + "/";
    }
    dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_START });
    api.get(url).then(response => {
      if (response.ok) {
        dispatch(fetchOnDemandClassesSuccess(response.data));
      } else {
        dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_FAIL });
      }
    });
  };
};

export const fetchCotdHomePageData = userId => {
  logger("making fetchCotdHomePageData api call")
  return dispatch => {
    let url = APIConstants.getCOTDHomePageData;
    if (userId !== null) {
      url = url + "/" + userId;
    } else {
      url = url + "/";
    }
    dispatch({ type: actionTypes.FETCH_COTD_DATA_START });
    api.get(url).then(response => {
      if (response.ok) {
        dispatch(fetchCotdHomePageDataSuccess(response.data));
      } else {
        dispatch({ type: actionTypes.FETCH_COTD_DATA_FAIL });
      }
    });
  };
};

export const fetchCotdHomePageDataSuccess = data => {
  return {
    type: actionTypes.FETCH_COTD_DATA_SUCCESS,
    classes: data
  };
};

export const fetchOnDemandDeepLinkClass = userId => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_START });

    let url = APIConstants.onDemandDeepLinkClassAPI;
    url = url + "/" + userId;

    userApi.get(url).then(response => {
      if (response.ok) {

        dispatch({
          type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_SUCCESS,
          classes: response.data
        });

      } else {
        dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_FAIL });
      }
    });
  };
};


export const fetchOnDemandClassesSuccess = data => {
  return {
    type: actionTypes.FETCH_ONDEMAND_CLASSES_SUCCESS,
    classes: data
  };
};

export const fetchConnectDeepLinkClass = classId => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_START });

    let url = `${APIConstants.clubLiveConnectAPI}${config.name}/videos/${classId}`;
    api.get(url).then(response => {

      if (response.ok) {
        logger(response);
        let clubData = response.data[0];
        const getMaxResolutionThumbnail = (picturesObj) => {
          if (picturesObj.length === 0) {
            return;
          }

          var max = picturesObj[0].height;
          var maxIndex = 0;

          for (var i = 1; i < picturesObj.length; i++) {
            if (picturesObj[i].height > max) {
              maxIndex = i;
              max = picturesObj[i].height;
            }
          }

          return maxIndex;
        }
        let video = {
          tag: clubData.id,
          streamingLink: clubData.streamingLink,
          creationDate: clubData.created_Time ? clubData.created_Time : "",
          trailerLinkWeb: clubData.trailerLink,
          classDescription: clubData.description,
          className: clubData.name,
          imageLink: clubData.pictures ? clubData.pictures.sizes[getMaxResolutionThumbnail(clubData.pictures.sizes)].link : "",
          durationSecond: clubData.duration,
        };

        dispatch({
          type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_SUCCESS,
          classes: [video]
        });
        logger(response.data);
      } else {
        dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_FAIL });
      }
    });
  };
};

export const FetchClubLiveConnect = (page = 1) => {

  return dispatch => {
    if (config.connectSource && config.connectSource.toLowerCase() === "wexer") {
      dispatch(searchOnDemandClass('', '', false, 1, 0, 3, false, '', '', '', '', '', '', '', '', '', false, config.providerId))
    }else{
    if (page === 1)
      dispatch({
        type: actionTypes.FETCH_LIVECONNECT_START
      });
    let url = `${APIConstants.clubLiveConnectAPI}${config.name}/videos?page=${page}`;
    api.get(url).then(response => {
      if (response.ok) {
        // if (config.connectSource && config.connectSource.toLowerCase() === "wexer") {
        //   response.data = {
        //     data: modifyFLLResponse(response.data.results)
        //   }
        // }
        dispatch({
          type: actionTypes.FETCH_LIVECONNECT_SUCCESS,
          data: response.data
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_LIVECONNECT_FAIL,
          result: response.data
        });
      }
    });
  };
} };


export const FetchLSConnectHome = () => {

  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_LSCONNECTHOME_START
    });
    // let url = `${APIConstants.clubLiveConnectAPI}${config.name}/preview/3`;
    let showFLLOnDemand = false;
    let url = config.connectSource && config.connectSource.toLowerCase() === "wexer" ?
      `${APIConstants.fetchFLLEvents}/3` :
      `${APIConstants.clubLiveConnectAPI}${config.name}/preview/3`;
    api.get(url).then(response => {
      if (response.ok) {
        if (config.connectSource && config.connectSource.toLowerCase() === "wexer") {
          if(config.ShowLiveConnect && response.data.results.length === 0) {
            showFLLOnDemand = true
            dispatch(searchOnDemandClass('', '', false, 1, 0, 3, false, '', '', '', '', '', '', '', '', '', false, config.providerId))
          }else{
            response.data = {
              //response.data.results
              data: modifyFLLResponse(response.data.results),
              recordType: "Live"
            }
          }   
        }
        if( !showFLLOnDemand){
          dispatch({
            type: actionTypes.FETCH_LSCONNECTHOME_SUCCESS,
            data: response.data
          });
        }
      } else {
        dispatch({
          type: actionTypes.FETCH_LSCONNECTHOME_FAIL,
          result: response.data
        });
      }
    });
  };
};

function modifyFLLResponse(dummyResponse) {
  let today = new Date();
  // for (let i = 0; i < 7; i++) {
  //   weekArr.push(new Date(today.getTime() + i * 24 * 60 * 60 * 1000))
  // }
  var modifiedObj = dummyResponse.reduce((acc, curr, i) => {
    let weekArr = []
    let daily_Time = curr.schedule.daily_Time.substring(11) + ".000Z"
    var availableTime = today.toISOString().split('T')[0] + 'T' + daily_Time
    for (let i = 0; i < 7; i++) {
      weekArr.push(new Date(new Date(availableTime).getTime() + i * 24 * 60 * 60 * 1000))
    }
    let activeTime = curr.schedule.next_Occurence_Time + ".000Z"
    acc.push({
      activeTime ,
      created_Time: curr.creationDate + ".000Z",
      description: curr.description,
      stream_Description: curr.description, //homePage description property
      id: curr.event_Id,
      type:curr.type,
      live: {
        status: curr.video ?
          curr.video.durationSecond ?
            new Date(activeTime).getTime() >= today.getTime() ? "unavailable" :
              new Date(activeTime).getTime() + curr.video.durationSecond * 1000 > today.getTime() ?
                // "streaming" : "unavailable" : "unavailable" : "unavailable",
                "streaming" : "unavailable" : liveEventStatusForWexer(curr) : liveEventStatusForWexer(curr),
        //"streaming", //"unavailable" 
        active_Time: activeTime

      },
      videoId: curr.video && curr.video.tag ? curr.video.tag : '',
      streamingLink: curr.video ? curr.video.streamingLink : "",//homePage streamingLink property
      liveStreamingLink: curr.video ? curr.video.streamingLink : "",
      duration: curr.video ? curr.video.durationSecond : "", //for player  
      name: curr.title,
      title: curr.title, //homePage title property
      next_Occurrence_Time: activeTime,
      pictures: {
        sizes: [{
          height: 1080,
          link: curr.imageUrl ? curr.imageUrl + "?width=640&height=320" : "",
          width: 1920
        }]
      },
      schedule: {
        availableDate: [activeTime],
        daily_Time
      }
    })
    return acc;
  }, [])
  return modifiedObj;
}

export const FetchLSConnectList = (page = 1) => {

  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_LSCONNECTLIST_START
    });
    //let url = `${APIConstants.clubLiveConnectAPI}${config.name}/live?page=${page}`;
    let url = config.connectSource && config.connectSource.toLowerCase() === "wexer" ?
      `${APIConstants.fetchFLLEvents}` :
      `${APIConstants.clubLiveConnectAPI}${config.name}/live`;
    api.get(url).then(response => {
      if (response.ok) {
        if (config.connectSource && config.connectSource.toLowerCase() === "wexer") {
          response.data = {
            eventData: modifyFLLResponse(response.data.results)
          }
        }
        dispatch({
          type: actionTypes.FETCH_LSCONNECTLIST_SUCCESS,
          data: response.data
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_LSCONNECTLIST_FAIL,
          result: response.data
        });
      }
    });
  };
};

export const resetFetchClubLiveConnect = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_LIVECONNECT_SUCCESS,
      data: ''
    });
  };
}


export const FetchRecordedClasses = (event_id, nextOccurence='', videoId = '',showLoading=true) => {

  return (dispatch,getState) => {
    if(showLoading){
      dispatch({
        type: actionTypes.FETCH_RECORDEDCLASSES_START
      });
    }
    let url = ''
    let userID = localStorage.getItem('userId');

    if (config.connectSource && config.connectSource === 'wexer')
    {
        if(userID)
        {
            if(videoId.length>0)
              url = `${APIConstants.fetchFLLAutoPublishVideos}${config.tenantID}/live_event/${event_id}/videos/${videoId}/${userID}`;
            else
              url = `${APIConstants.fetchFLLAutoPublishVideos}${config.tenantID}/live_event/${event_id}/videos?userid=${userID}`;
        }
        else
        {
          if(videoId.length>0)
            url = `${APIConstants.fetchFLLAutoPublishVideos}${config.tenantID}/live_event/${event_id}/videos/${videoId}`;
          else
            url = `${APIConstants.fetchFLLAutoPublishVideos}${config.tenantID}/live_event/${event_id}/videos`;
        }
    }
    else
      url = `${APIConstants.clubLiveConnectAPI}${config.name}/live_event/${event_id}/videos`;
    const {onDemand:{RecordedClasses}} = getState();
    api.get(url).then(response => {
      if (response.ok) {

        if (config.connectSource && config.connectSource === 'wexer'&&Array.isArray(response.data)){
          if(!isEqual(RecordedClasses,{ ...response.data[0], event_id, eventViewDate: nextOccurence })){
            dispatch({
              type: actionTypes.FETCH_RECORDEDCLASSES_SUCCESS,
              data: { ...response.data[0], event_id, eventViewDate: nextOccurence }
            });
          }
        }
        else{
          if(!isEqual(RecordedClasses,{ ...response.data, event_id, eventViewDate: nextOccurence })){
            dispatch({
              type: actionTypes.FETCH_RECORDEDCLASSES_SUCCESS,
              data: { ...response.data, event_id, eventViewDate: nextOccurence }
            });
          }
        }
      } else {
        dispatch({
          type: actionTypes.FETCH_RECORDEDCLASSES_FAIL,
        });
      }
    });
  };
};

/**
 * Nikhil Gupta 08-09-2020
 * Get video details when the video is clicked on My Activity page
 * @param {*} event_id 
 * @param {*} videoId 
 */
export const FetchLSVideoDetails = (event_id, videoId) => {

  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_LSVIDEODETAILS_START
    });
    let url = `${APIConstants.clubLiveConnectAPI}${config.name}/live_event/videos/${videoId}`;
    api.get(url).then(response => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_LSVIDEODETAILS_SUCCESS,
          data: { ...response.data, event_id, videoId }
        });
        if (response.data.transcode_Status !== "complete") {
          dispatch({
            type: actionTypes.VIDEO_ERROR,
            error: Translate({ id: "liveStream.TranscodingError" })
          });
        }
      } else {
        dispatch({
          type: actionTypes.FETCH_LSVIDEODETAILS_FAIL,
        });
      }
    });
  };
};
export const FetchPlayLink = (classId) => () => {
  return api.get(`${APIConstants.playbackURL}${classId}/${localStorage.getItem('userId')}`)
}
export const setStrimingLink = (data)=> (dispatch)=>{
  const dispatchData = data ? {type: actionTypes.FETCH_PLAYBACK_STREAMINGLINK_SUCCESS,data} : {type: actionTypes.FETCH_STREAMINGLINK_FAIL};
  dispatch(dispatchData);
}
export const FetchPlayBackLink = (classId,notDeepLink=false) => {
  return (dispatch,getState) => {
    const {onDemand:{StreamingVideoLink}} = getState();
    // error fixing in deeplink case
    if(!notDeepLink)  getLink(classId,dispatch,StreamingVideoLink)
    else{
      if(!isEmpty(StreamingVideoLink))
      {
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_START
        });
      }
      else getLink(classId,dispatch)
    }
  };
};
const getLink = (classId,dispatch,StreamingVideoLink)=>{
  if(!isEmpty(StreamingVideoLink))
      {
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_START
        });
      }
  let userId = localStorage.getItem('userId');
  let url = `${APIConstants.playbackURL}${classId}/${userId}`;
        api.get(url).then(response => {
          if (response.ok) {
            dispatch({
              type: actionTypes.FETCH_PLAYBACK_STREAMINGLINK_SUCCESS,
              data: response.data.results
            });
          } else {
            dispatch({
              type: actionTypes.FETCH_STREAMINGLINK_FAIL
            });
          }
        });
}
export const FetchStreamingLink = (event_id) => {
  return dispatch => {
    let userId = localStorage.getItem('userId');
    // dispatch({
    //   type: actionTypes.FETCH_STREAMINGLINK_START,
    // });
    let url = `${APIConstants.clubLiveConnectAPI}${config.name}/live_event/${event_id}/streamingurl/${userId}`;
    api.get(url).then(response => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_SUCCESS,
          data: response.data
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_FAIL
        });
      }
    });
  };
};

export const clearVideoError = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.VIDEO_ERROR,
      error: null
    });
  }
}

export const fetchIntensity = () => {
  return dispatch => {
    api.get(APIConstants.intensityAPI).then(response => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_INTENSITY,
          intensities: response.data
        });
      }
    });
  };
};

export const logOnDemandTracking = (selectedClass, userId, elapsedTime, isClubConnect) => {
  if (selectedClass.id) {
    var body = {
      requestsource: 1,
      subscriptiontag: "",
      performDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      providerName: selectedClass.provider ?? selectedClass?.provider_id,
      providerId:selectedClass.providerID ?? selectedClass?.newProviderId,
      contentID: selectedClass.id,
      contentName: selectedClass.title,
      // actualDurationSecond: selectedClass.duration * 60,
      playedDurationSecond: elapsedTime,
      subscriptionStartDate: "",
      userId: userId,
      ID: userId + "-" + selectedClass.id + "-" + moment().unix()
    };
    if(selectedClass?.duration){
      body.actualDurationSecond = selectedClass?.durationSecond ?? selectedClass?.durationInSec
    }
  } else {
    // eslint-disable-next-line no-redeclare
    var body = {
      requestsource: 1,
      subscriptiontag: "",
      performDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      providerName: selectedClass?.provider,
      providerId:selectedClass.providerID,
      contentID: selectedClass.tag,
      contentName: selectedClass.className,
      // actualDurationSecond: selectedClass.durationSecond,
      playedDurationSecond: elapsedTime,
      subscriptionStartDate: "",
      userId: userId,
      ID: userId + "-" + selectedClass.tag + "-" + moment().unix()
    };
    if(selectedClass?.durationSecond){
      body.actualDurationSecond = selectedClass.durationSecond
    }
  }
  if (isClubConnect) {
    body = { ...body, providerName: "Wexer Demo" }
  }
  if(body.providerName === undefined){
    body.providerName = config.name
  }
  if(body.providerId === undefined){
    body.providerId = config.providerId
  }
  return dispatch => {
    if (body.playedDurationSecond > 0) {
      dispatch({
        type: actionTypes.CLASS_LOGGED,
        playedDurationSecond: body.playedDurationSecond
      });

      api.post(APIConstants.logOnDemandTrackingAPI, body).then(response => { });
    }
  };
};

export const setClassFavourite = (
  selectedClassTag,
  userId,
  isSettingFavourite
) => {
  /*  dispatch({ type: actionTypes.IS_FAV, isFav: isSettingFavourite }); */
  if (isSettingFavourite) {
    const body = {
      userId: userId,
      virtualClassTag: selectedClassTag
    };

    return dispatch => {
      userApi.post(APIConstants.setClassFavourite, body).then(response => { });
    };
  } else {
    let url = APIConstants.setClassFavourite;
    url = url + "/" + selectedClassTag;
    return dispatch => {
      userApi.delete(url).then(response => { });
    };
  }
};

export const setClassFavouriteOnLocalColletion = (selectedCollection, classId, isSettingFavourite) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_FAV_LOCAL,
      payload: {
        collectionName: selectedCollection,
        tag: classId,
        isSettingFavourite
      }
    });
  };
};
//below one for deeplink class
export const setDeeplinkClassFavUnfavOnLocalColletion = (Collection) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_FAV_UNFAV_LOCAL,
      payload: [Collection]
    });
  };
};
//below one for deeplink collection
export const setDeeplinkCollectionFavUnfavOnLocal = (id) => {
  logger(id)
  return dispatch => {
    dispatch({
      type: actionTypes.SET_FAV_UNFAV_LOCAL_COLLECTION,
      index: id
    });
  };
};
export const changeSkippedState = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CHANGE_SKIPPED_STATE
    });
  };
};

export const clearClassData = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAR_CALSS_DATA
    });
  };
};
export const clearLiveStreamRecordedData = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAR_LIVESTREAM_RECORDED_DATA
    });
  };
};
export const clearSearchData = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAR_SEARCH_CALSS_DATA
    });
  };
};
export const clearCategorySearchData = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAR_CATEGORY_SEARCH_CALSS_DATA
    });
  };
};
export const clearFavClassData = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAR_FAV_CALSS_DATA,
      classes: null
    });
  };
};
export const searchMetadata = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.SEARCH_METADATA_START
    });
    let url = APIConstants.onDemandSearchMetadataAPI;
    let userId = localStorage.getItem('userId');

    if (userId !== null) {
      url = url + "/" + userId;
    } else {
      url = url + "/";
    }

    api.get(url).then(response => {

      dispatch({
        type: actionTypes.GET_SEARCH_METADATA,
        Metadata: response.data

        /////////////////////////////////////////////////////////////
        //delete the changes 
        //only for testing the single category
        //Nikhil Gupta
        // Metadata: {...response.data, categories : [response.data.categories[0]]}
      });
    });
  };
};

export const searchOnDemandClass = (
  searchTerm,
  keyword,
  fromRecent,
  searchType,
  skip,
  take = 50,
  ShowMetaData = true,
  focusArea = '',
  skill = '',
  equipment = '',
  duration = '',
  intensity = '',
  searchText = '',
  dir = 'desc',
  classLanguage = '',
  subcategory = '',
  fromSearch = false,
  providerId = '',
  sort='scheduledate'
) => {
  let body;
  let userId = localStorage.getItem('userId');
  if (userId === null) {
    userId = ''
  }
  if (fromRecent) {
    body = {
      virtualClasses: searchTerm
    };
  } else if (!fromRecent && searchType === 1) {
    if (dir.length > 0) {
      if (dir === "Newest"||dir==="desc")
        dir = 'desc'
      else
        dir = 'asc'
    }
    body = {
      query: searchText,
      take: take,
      skip: skip ? skip : 0,
      categoryId: searchTerm,
      labels: keyword.length > 0 ? keyword : '',
      type: '',
      // focusArea: focusArea.length > 0 ? focusArea : '',
      skill: skill.length > 0 ? skill : '',
      // equipment: equipment.length > 0 ? equipment : '',
      duration: duration,
      intensity: intensity.length > 0 ? intensity : '',
      dir: dir,
      classLanguageName: classLanguage,
      subcategory: subcategory,
      providerid: providerId !== '' ? providerId : '',
      sort:sort
    };
    //Remove type and put categoryId
  } else if (!fromRecent && searchType === 0) {
    let presecond;
    if (searchTerm[0] !== "") {
      // compareDurationMins will pick string values from PO editor as Duration selector
      if (searchTerm[0] === compareDurationMins()["10mins"]) {
        presecond = "0,960";
      } else if (searchTerm[0] === compareDurationMins()["20mins"]) {
        presecond = "961,1559";
      } else if (searchTerm[0] === compareDurationMins()["30mins"]) {
        presecond = "1560,2159";
      } else if (searchTerm[0] === compareDurationMins()["40mins+"]) {
        presecond = "2160,18000";
      } else if (searchTerm[0] === "all") {
        presecond = "0,18000";
      }
    }
    body = {
      duration: presecond,
      type: searchTerm[1],
      provider: searchTerm[2],
      take: 50,
      skip: skip ? skip : 0
    };
  } else if (searchType === undefined) {
    if (searchTerm) {
      body = { duration: "0,30800" };
    }
  }

  return (dispatch,getState) => {
    const {onDemand} = getState();
    const filterFocusArea = focusArea.length > 0 ? focusArea : '';
     if(!isEmpty(filterFocusArea)){
      const getFocusArea = onDemand?.Metadata?.focusArea.filter((item)=>item.name === filterFocusArea)
      if(getFocusArea.length){
        body.focusAreaTags = getFocusArea[0].tag;
      }
    }
    const filterEquipment = equipment.length > 0 ? equipment : '';
    if(!isEmpty(filterEquipment)){ 
      const getEquipmentName = onDemand?.Metadata?.equipmentList.filter((item)=>item.name === filterEquipment)
      if(getEquipmentName.length){
        body.equipmentTags = getEquipmentName[0].typeTag;
      }
    }
    if (!skip) {
      if (!fromSearch) {
        dispatch({
          type: actionTypes.SEARCH_ONDEMAND_START
        });
      }
      else {
        dispatch({
          type: actionTypes.FILTER_BY_SEARCH_START
        });
      }
      if (searchType === 0) {
        dispatch({
          type: actionTypes.CATEGORY_PARAMS_SKIPPED,
          prevSearchParams: searchTerm
        });
      }
    }

    if (providerId !== '' && !fromSearch) {
      dispatch({
        type: actionTypes.FETCH_ONDEMAND_START
      });
    }
    api.setHeader("ShowMetaData", ShowMetaData);
    setHeaderLanguage(api);
    api.put(APIConstants.onDemandNewSearchAPI + userId, body).then(response => {
      if (fromRecent) {
        dispatch({
          type: actionTypes.SEARCH_ONDEMAND_CLASS_RECENT_SUCCESS,
          classes: response.data
        });
      } else {
        if (skip) {
          if (searchType === 0) {
            dispatch({
              type: actionTypes.SKIPPED_CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS,
              classes: response.data,
              searchType: searchType
            });
          } else {
            dispatch({
              type: actionTypes.SKIPPED_SEARCH_ONDEMAND_CLASS_SUCCESS,
              classes: response.data,
              searchTerm: searchTerm,
              searchType: searchType
            });
          }
        } else {
          if (searchType === 0) {
            dispatch({
              type: actionTypes.CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS,
              classes: response.data,
              searchType: searchType
            });
          } else {
            if (!fromSearch) {
              if (providerId !== '') {
                dispatch({
                  type: actionTypes.FETCH_ONDEMAND_SUCCESS,
                  data: response.data
                });
              }
              else {
                dispatch({
                  type: actionTypes.SEARCH_ONDEMAND_CLASS_SUCCESS,
                  classes: response.data,
                  searchTerm: searchTerm,
                  searchType: searchType
                });
              }
            }
            else {
              dispatch({
                type: providerId !== '' ? actionTypes.FLL_FILTER_ONDEMAND_SUCCESS : actionTypes.FILTER_BY_SEARCH,
                classes: response.data,
                searchTerm: searchTerm,
                searchType: searchType
              });
            }
          }
        }
      }
    });
    // return Promise.resolve();
  };
};

export const getFavouritedClasses = () => {
  return dispatch => {
    dispatch({type:actionTypes.GET_FAVOURITED_CLASSES})
    userApi.get(APIConstants.setClassFavourite).then(response => {
      if (response.ok) {
        dispatch({ type: actionTypes.SET_FAVOURITED_CLASSES, favoritedClasses: response.data });
      }
      else
        dispatch({ type: actionTypes.SET_FAVOURITED_CLASSES})
     });
  };
};

export const sendCategoryDetailsToLocalytics = params => {
  return dispatch => {
    dispatch({ type: actionTypes.SEND_CATEGORY_TO_LOCALYTICS, params: params });
  };
}; 
export const storeSearchParams = params => {
  return dispatch => {
    dispatch({ type: actionTypes.STORE_SEARCH_PARAMS, params: params });
  };
};
export const selectOnDemandClass = sClass => {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECT_ONDEMAND_CLASS,
      selectedClass: sClass
    });
  };
};

export const updateBrowseClasses = data => {
  return dispatch => {
    dispatch({
      type: actionTypes.SEARCH_ONDEMAND_CLASS_SUCCESS,
      classes: data,
    });
  };
};

export const resetBrowseClassData = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAR_SEARCH_CALSS_DATA,
    });
  };
};
export const resetFllOndemand = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_FLL_ONDEMAND,
    });
  };
};
export const updateFllOnDemandData = data => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_ONDEMAND_SUCCESS,
      data: data
    });
  };
};

export const liveStreamStarted = status => {
  return dispatch => {
    dispatch({
      type: actionTypes.LIVE_STREAM_STARTED,
      status
    });
  };
};
