import React, {Fragment, useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Translate } from "../../utils/Translate";
import { Grid, Typography } from "@material-ui/core";
import Routes from '../../components/Router/Routes';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {getTranslatedFile } from '../../utils/utilityfunctions';
import { useBrowseClasses } from "../../hooks/useBrowseClasses";

const config = require("../../assets/config.json");

const BrowseClasses = (props) => {
  const { useBrowseClassesState, useBrowseClassesDispatch } = useBrowseClasses();
  const { metadata } = useBrowseClassesState()
  const { resetBrowseClassData,setClassesSearchData,setPaginatedData,resetFilters } = useBrowseClassesDispatch()
  const [categories, setCategories] = useState([])
  const [showArrow] = useState(props.showArrow)

  useEffect(() => {
    if (metadata?.categories&&metadata.categories.length>0) {
      const language = getTranslatedFile()
      /// commenting below line as it's crashing for ZERO category Id
      //let sortedCategories=metadata.categories.sort((a,b)=>language.categoryName[a.id].localeCompare(language.categoryName[b.id]))
      let sortedCategories=metadata.categories.sort((a, b) => {
        try{
          const categoryNameA = language.categoryName[a.id];
          const categoryNameB = language.categoryName[b.id];
          if (categoryNameA && categoryNameB) {
            return categoryNameA.localeCompare(categoryNameB);
          }
          return false;
        }
        catch(e){
          return false;
        }
      });
      setCategories(sortedCategories)      
    }
  }, [metadata])

  const navigateToBrowseClasses=(category)=>{
    resetBrowseClassData()
    setClassesSearchData()
    setPaginatedData()
    resetFilters()
    props.history.push({ pathname: `${Routes.categories}${category.id}`});
  }

  const getCategoryImage = (imageName) => {
    try {
      return <img src={require(`../../assets/images/${imageName}.jpg`)} alt={`${imageName}`} />;
    } catch {      
      return <img src={require(`../../assets/images/1001.jpg`)} alt=''  />;
    }
  }

  return (
    <Fragment>
      {categories && categories.length > 1 && <Grid item className="mobileScrollParent">
        <Grid container={(config.showLiveEvent||showArrow)?true:false} alignItems="center" direction={(config.showLiveEvent||showArrow)?"row":"column"} className="m-t-30 m-t-xs-32">
            <Grid item className={showArrow?"align-left makeTextClickable adjustTitleWidth":config.showLiveEvent?"align-left adjustTitleWidth":""}
            onClick={() => { props.history.push({ pathname: Routes.classListViewRoute }); }}>
              <Typography variant="h1" className={(config.showLiveEvent||showArrow)?"align-left clear":""}>
              {Translate({ id: "homePage.BrowseClassesTitle" })}
              </Typography>
            </Grid>
            {showArrow && <Grid item className="makeTextClickable" 
            onClick={() => { props.history.push({ pathname: Routes.classListViewRoute }); }}>
                <KeyboardArrowRightIcon
                  className="align-bottom makeTextClickable iconColor"
                  style={{ fontSize:"2.7rem" }}
                  onClick={() => { props.history.push({ pathname: Routes.classListViewRoute }); }}
                />
            </Grid>}
          </Grid>
          <Grid item className="m-b-10 m-b-xs-10">
            <Typography variant="h6" className={(config.showLiveEvent||showArrow)?"text-gray align-left":"text-gray"}>
              {Translate({ id: "homePage.BrowseClassesDescription" })}
            </Typography>
          </Grid>
        <Grid item className={(!config.showLiveEvent&&!showArrow)?"mobileScroll m-b-10 m-t-77":"mobileScroll m-b-10 m-t-15"}>
          <Grid container direction="row" className="squareImageContainer" spacing={4}>
            {categories.length > 1 && categories.map((category) => 
              <Grid  key={Math.random()} item className="align-left">
                <div className="imageContainer makeTextClickable" 
                  onClick={() => { navigateToBrowseClasses(category) }}>
                  {/* <img src={require("../../assets/images/sample1.jpg")} /> */}
                  {getCategoryImage(category.id)}                  
                  <div className="overlay" style={{ maxWidth: "173px" }}>
                    <div className="bottomText text-white">
                      <Typography variant="body2" className="m-b-10 m-b-xs-10">
                        {/* {category.name} */}
                        {Translate({ id: `categoryName.${category.id}`})}
                      </Typography>
                    </div></div></div>
              </Grid>)}
          </Grid>
        </Grid>
        </Grid>}
        </Fragment>
  )
}

export default withRouter(BrowseClasses);
