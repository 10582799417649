/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router'
import ClubList from '../../hoc/ClubListContainer';
import Livestreamlist from '../LiveStreaming/LiveStreamList';
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import Routes from '../../components/Router/Routes';
// import {saveSelectedDate} from "../../store/actions/ondemand";
import { useWrapperList } from "../../hooks/useWrapperList";

var config = require("../../assets/config.json");

function WrapperList(props) {
  const [showOnDemand, setShowOnDemand] = useState(false)
  const pathname = props.location.pathname
  const { useWrapperListDispatch } = useWrapperList();
  const { resetFetchClubLiveConnect } = useWrapperListDispatch();

  useEffect(() => {
    if(pathname&&(pathname.includes('schedule')||pathname.toLowerCase().includes('clublist'))){
      setShowOnDemand(false)
    }
    else{
      setShowOnDemand(true)
    }
    //type === "classes" ? setShowOnDemand(true) :       setShowOnDemand(false)
  }, [pathname])

  return (
    <Fragment>
      {config.showLiveEvent && config.ShowLiveConnect && <div className="page-container align-left topProfileLink m-t-15">
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid item>
            <a
              onClick={() => {
                props.history.push(Routes.connectLiveSchedule)
                resetFetchClubLiveConnect()
              }}
              className = {`${!showOnDemand ? `${config.name} dynamiclinks makeTextClickable` : "makeTextClickable"}`}
            >
              <Typography variant="body1">
                {Translate({ id: "connect.LiveScheduleSubNavText" })}
              </Typography>
            </a>
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <a
              onClick={() => {
                // dispatch(saveSelectedDate({}))
                props.history.push({ pathname: Routes.connectOndemandClasses });
              }}
              className={`${showOnDemand ? `${config.name} dynamiclinks makeTextClickable` : "makeTextClickable"}`}>
              <Typography variant="body1">
                {Translate({ id: "connect.OnDemandSubNavText" })}
              </Typography>
            </a>
          </Grid>
        </Grid>
      </div>}
      {config.showLiveEvent ? <Livestreamlist showOnDemand={showOnDemand} /> : (config.ShowLiveConnect === true ? <ClubList /> : null)}
    </Fragment>
  )
}
export default (withRouter(WrapperList));



