import React, { Component } from "react";
import "./theme/styles/App.scss";
import Layout from "./hoc/LayoutContainer";
import { Grid } from "../node_modules/@material-ui/core";
import { withRouter } from "react-router-dom";
import Router from "./components/Router/Router";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { authCheckState, SaveUserConsent } from "./store/actions/auth";
import { searchMetadata } from "./store/actions/ondemand";
import {
  LatestTncStatus,
  getTNCConsent,
} from "./store/selectors/Consents";
import CustomModal from "./components/Modal/Modal";
import {AddDependency} from "./components/VideoPlayer/AddDependency";
import { Translate } from "./utils/Translate";
import { ConsentAction } from "./utils/constants";
import axios from 'axios';
import ReactHtmlParser from "react-html-parser";
import logger from "../src/utils/logger"
import { isEmbeddedMode } from "./utils/utilityfunctions";

const config = require('./assets/config.json')

class App extends Component {
  componentDidMount() {
    
    window.scrollTo(0, 0);
    /**
     * It makes in api call to firebase then checks to localstorage version
     */
    const version = localStorage.getItem("version");
    if (version != null) {
      logger("Checking for New version Available")
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        logger("dev mode")
      } else {
        try {

          axios.get(process.env.PUBLIC_URL + "/manifest.json").then((file) => {
            logger(`Old Version :${version.toString()}`);
            logger(`New Version ${file.data.verson.toString()}`);
            if (typeof version.toString() == typeof file.data.verson.toString()) {
              if (file.data.verson.toString() !== version.toString()) {
                localStorage.setItem("version", file.data.verson.toString());
                if ((!config.embeddedConfig || config.embeddedConfig.isEmbedded === false) ^ (config.ssoLogin === true && !!sessionStorage.getItem("embededConfig") === false )) {
                  const contentText = document.getElementById("app-update")?.textContent;
                  alert(contentText ?? "New content is available, please refresh your browser");
                }else if(isEmbeddedMode() === false){
                  const contentText = document.getElementById("app-update")?.textContent;
                  alert(contentText ?? "New content is available, please refresh your browser");
                }
                window.location.reload(true);
              }
            } else {
              window.location.reload(true);
            }
          })
        } catch (e) {
          logger("something went wrong")
          window.location.reload(true);
        }
      }
    }




    //if(va){}

    var sUsrAg = navigator.userAgent;
    var style = document.createElement("style");
    if (sUsrAg.indexOf("Chrome") > -1) {
      style.innerHTML = `${window.screen.width === 1280
          ? ".slider-Featured-Arrow {padding-left:0px !important}"
          : ""
        }
      ${window.screen.width < 1366
          ? "@media (max-width: 1400px) and (min-width: 1251px) {.SearchHolder > * {width: 33.333333333%; min-width: 400px !important;}}"
          : ""
        }`;
    } else if (sUsrAg.indexOf("Safari") > -1) {
      style.innerHTML = ` ${window.screen.width === 1440
          ? ".slider-Featured-Arrow {padding-left:0px !important}"
          : ""
        } ${window.screen.width < 1366
          ? "@media (max-width: 1400px) and (min-width: 1251px) {.SearchHolder > * {width: 33.333333333%; min-width: 300px !important;}}"
          : ""
        }`;
    }

    var ref = document.querySelector("script");
    ref.parentNode.insertBefore(style, ref);

    this.props.onUserInitialization();

    //if (localStorage.getItem("userId") != null) {
    this.props.searchMetadata();
    //}
  }
  state = {
    tncModelOpen: true,
    mktgModelOpen: true,
    tncHtml: null,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.consents != null &&
      prevProps.consents !== this.props.consents
    ) {
      for (var i = 0; i < this.props.consents.length; i++) {
        if (this.props.consents[i].policy === "tnc") {
          this.setState({ tncHtml: this.props.consents[i].policyHtml });
        }
      }
    }
    if (
      this.props.Metadata && 
      prevProps.Metadata !== this.props.Metadata
    ) {
      localStorage.setItem("classTypes", [this.props.Metadata.classTypes]);
      localStorage.setItem("ProviderType", this.props.Metadata.providers);
    }
  }

  acceptTnc = tag => {
    this.props.saveUserConsent(tag, ConsentAction.Accepted);
    this.setState({ tncModelOpen: false });
  };

  acceptMktg = tag => {
    this.props.saveUserConsent(tag, ConsentAction.Accepted);
    this.setState({ mktgModelOpen: false });
  };

  cancelMktg = tag => {
    this.props.saveUserConsent(tag, ConsentAction.Rejected);
    this.setState({ mktgModelOpen: false });
  };

  checkModelStatus = () => {
    this.setState({ tncmodelOpen: true });
  };
  forContent = ()=>{
    return <span id="app-update" className="noneDisplay">{Translate({ id: "app.Update" })}</span>;
  }
  render() {
    let tncModal = null;

    if (!this.props.isLatestTncAccepted) {
      tncModal = (
        <CustomModal
          open={this.state.tncModelOpen}
          title={Translate({ id: "Reconsent.TncTitle" })}
          isContentScrollable={true}
          okButtonClick={() => {
            this.acceptTnc(this.props.tncConsentDetail.tag);
          }}
          okButtonText={Translate({ id: "Actions.Accept" })}
        >
          <div>
            {this.state.tncHtml ? ReactHtmlParser(this.state.tncHtml) : null}
          </div>
        </CustomModal>
      );
    }

    return (
      // <MuiThemeProvider theme={theme}>
      <React.Fragment>
        {this.state.tncHtml && tncModal}
        {this.forContent()}
        <div className="App">
          <AddDependency />
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <Layout>
                <Router />
              </Layout>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
      // </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  const isShowTnc = config.showTncAccepted === undefined ^ config.showTncAccepted === true;
  return {
    isLatestTncAccepted: isShowTnc ? LatestTncStatus(state) : true,
    Metadata: state.onDemand.Metadata,
    consents: state.auth.consents,
    tncConsentDetail: getTNCConsent(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserInitialization: () => dispatch(authCheckState()),
    searchMetadata: () => dispatch(searchMetadata()),
    saveUserConsent: (consentTag, action) =>
      dispatch(SaveUserConsent(consentTag, action)),
  };
};

//export default App;
export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  )
);
