import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";

const LiveScheduleShimmer = (props) => {
    return (
        <div className="liveShimmer mobileScroll m-t-15">
            <Skeleton variant="text" width={'30%'} height={25} />
            <Skeleton variant="text" width={'40%'} height={15} />
            <Grid container justifyContent="flex-start" spacing={4} className="cardList m-t-5 flexNoWrap liveShimmerCards">
                {[1, 2, 3].map((i) =>
                    <Grid key={i} item lg={4} md={6} sm={12} xs={12} >
                        <Skeleton variant="rect" className="Trailer thumbnail cardBorder" style={{minHeight:"30vh"}} />
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
export default LiveScheduleShimmer;
