import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Typography } from "@material-ui/core";
import { Translate } from '../../utils/Translate';
import InfiniteScroll from "react-infinite-scroller";
import Spinner from '../../components/spinner/spinner'
import ClubCardNew from './ClubCardNew';
import LoadingClassesShimmer from "../../components/Shimmer/LoadingClassesShimmer";
import OnDemandFilter from "../LiveStreaming/FllOndemand/OnDemandFilter"
import { useClubList } from "../../hooks/useClubList"

import logger from "../../utils/logger"
var config = require("../../assets/config.json");

function ClubList(props) {
    const { useClubListDispatch } = useClubList();
    const { fetchClubLiveConnect } = useClubListDispatch();

    const [clubClasses, setClubClasses] = useState(props.clubClasses ? props.clubClasses.data : null);
    const [isLoading, setLoading] = useState(false);

    const [apiOnDemandPageCount, setApiOnDemandPageCount] = useState(1)
    const [paginatedDataOnDemand, setPaginatedDataOnDemand] = useState([])
    const [hasMoreDataOnDemand, setHasMoreDataOnDemand] = useState(false);
    const [allRecordsLeftOnDemand, setAllRecordsLeftOnDemand] = useState(0)
    const [onDemandTotalPages, setOnDemandTotalPages] = useState(0)

    useEffect(() => {
        logger(clubClasses);
        if (props.clubClasses == null||props.clubClasses === "") {
            var path = props.location.pathname;
            config.connectSource !== 'wexer' && props.FetchClubLiveConnect();
            // setLoading(true);
            props.storeDeepLinkDataToStore(path)
        } else {
            setClubClasses(props.clubClasses.data);
            setLoading(false);
            if (props.clubClasses.recordsLeft > 0) {
                setHasMoreDataOnDemand(true)
            } else {
                setHasMoreDataOnDemand(false)
            }
            setAllRecordsLeftOnDemand(props.clubClasses.recordsLeft)
            let totalPages = (props.clubClasses.totalRecords / props.clubClasses.perPage) + 1
            setOnDemandTotalPages(totalPages)
            if (paginatedDataOnDemand.length === 0)
                setPaginatedDataOnDemand(props.clubClasses.data)
            else {
                setPaginatedDataOnDemand([...paginatedDataOnDemand, ...props.clubClasses.data])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clubClasses]);

    useEffect(() => {
        //reset clubClasses classes component at unmount
        return () => {
            props.resetFetchClubLiveConnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadMoreOnDemand = () => {
        setTimeout(() => {
            if (props.clubClasses && props.clubClasses.data && props.clubClasses.data.length > 0) {
                setHasMoreDataOnDemand(false)
                if (allRecordsLeftOnDemand > 0 && onDemandTotalPages >= apiOnDemandPageCount) {
                    setApiOnDemandPageCount(apiOnDemandPageCount + 1)
                }
                else {
                    setHasMoreDataOnDemand(false)
                }
            }
        }, 1000);
    }

    useEffect(() => {
        if (apiOnDemandPageCount > 1) {
            fetchClubLiveConnect(apiOnDemandPageCount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiOnDemandPageCount])

    return (
        <InfiniteScroll
            style={{ overflow: paginatedDataOnDemand && paginatedDataOnDemand.length > 0 ?"hidden" : "none" }}
            scrollThreshold="50%"
            datalength="50"
            // next={this.loadMore}
            // hasMore={this.props.itemsRemaining}
            loader={
                <Spinner
                    backColor="white"
                />
            }
            endMessage={
                <p style={{ display: "none" }}>
                    <b />
                </p>
            }
        >
            {isLoading ?
                <Spinner /> :
                <Fragment>
                    <div className="page-container">
                        <Typography variant="h1" className="">
                            {Translate({ id: "homePage.ConnectTitle" })}
                        </Typography>
                        <Typography variant="h6" className="text-gray m-b-20 m-b-xs-10">
                            {Translate({ id: "homePage.ConnectDescription" })}
                        </Typography>
                        {/* <Grid container justify="flex-start">
                        <Filter />
                    </Grid> */}
                    </div>
                    {config.connectSource !== 'wexer' && <div className={`${config.showLiveEvent ? "top0 m-t-15 p-t-32 p-t-xs-16" : ""}`}>
                        <div className="page-container">
                            {paginatedDataOnDemand && paginatedDataOnDemand.length > 0 &&
                                <InfiniteScroll
                                    datalength={10}
                                    loadMore={loadMoreOnDemand}
                                    hasMore={hasMoreDataOnDemand}
                                    loader={hasMoreDataOnDemand && !isLoading ? <LoadingClassesShimmer /> : null}
                                >
                                    <Grid container justify="flex-start" spacing={4}>
                                        {paginatedDataOnDemand.map((item, index) => (
                                            <Grid item lg={4} md={6} sm={12} xs={12} key={item.id}>
                                                <ClubCardNew clubData={item}></ClubCardNew>
                                            </Grid>))}
                                    </Grid>
                                </InfiniteScroll>}
                        </div>
                    </div>}
                    {config.connectSource === 'wexer' && <OnDemandFilter />}

                </Fragment>
            }
        </InfiniteScroll>
    )
}
export default ClubList



