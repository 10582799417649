import React from "react";


export default function NewCard() {
  return (
    <label className="featured-labelNew">
      New
    </label>
  );
}

