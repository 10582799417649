import React, { Fragment } from "react";
import { Button, Typography } from "@material-ui/core";
import { Translate } from '../../utils/Translate';
import { classStarted } from '../../utils/LivestreamTime';

function CountdownButton({ startTime, status, clickHandler, history }) {
    return (
        <Fragment>
            {/* {logger(!(new Date(startTime) < new Date() && status == "streaming"))} */}
            {(new Date(startTime) < new Date() && status === "streaming") ?
                (<Button className="view-btn bg-red"
                    color="secondary"
                    variant="contained"
                    onClick={()=>clickHandler}
                >
                    <Typography variant="h4">
                        {classStarted(startTime, status)}
                    </Typography>
                </Button>) : (
                    <Button className="view-btn btn-default"
                        color="secondary"
                        variant="contained"
                        onClick={()=>clickHandler}
                        >
                        <Typography variant="h4">
                            {Translate({ id: "liveStream.ViewDetails" })}
                        </Typography>
                    </Button>
                )}
        </Fragment>)
}
export default CountdownButton