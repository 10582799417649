import React from "react";
import { withRouter } from "react-router";
import ChannelsCardWrapper from "./ChannelsCardWarpper";
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Routes from "../../../src/components/Router/Routes";

class ChannelsHome extends React.Component {
  
  //  gotoPlayer=()=>{
  //      this.props.history.push({
  //          pathname:Routes.onDemandClassPlayerRoute + "",
  //      })
  //  }

  viewDetails = () => {
    this.props.history.push({
      pathname: "/channels",
      state: { clubClasses: this.props.clubClasses },
    });
  };
  ViewChannel = () => {
    this.props.history.push({
      pathname: Routes.channelDetailView,
      state: { clubClasses: this.props.clubClasses },
    });
  };

  render() {
    return (
       <div className="mobileScrollParent">           
          <Grid
            container
            direction="row"
            alignItems="center"
            className="m-t-30 m-t-xs-32"
          >
            <Grid
              item
              className="makeTextClickable"
              onClick={this.viewDetails}
            >
              <Typography variant="h1" className="align-left">
                {Translate({ id: "homePage.ChannelsTitle" })}
              </Typography>
            </Grid>

            <Grid  
              item
              className="makeTextClickable"
              onClick={this.viewDetails}
            >
              <KeyboardArrowRightIcon
                className="align-bottom makeTextClickable iconColor"
                style={{ fontSize:"2.7rem" }}
                onClick={this.viewDetails}
              />
            </Grid>            
          </Grid>
          <div
          className="align-left text-gray m-b-10">
            <Typography variant="h6" className="text-gray">
            {Translate({id:"homePage.ChannelsDescription"})}
            </Typography>  
          </div>
          <div className="mobileScroll m-t-15">      
          <Grid
            container
            justifyContent="flex-start"
            spacing={4}
            className="bg-white m-t-xs-5 flexNoWrap cardList"
          >
            {this.props.channelData.map(
              (channelData, index) =>
                -index < 3 && (
                  <Grid item lg={4} md={6} sm={12} xs={12} key={index}  className="channelHome">
                    <ChannelsCardWrapper
                        data={channelData}                      
                    />
                  </Grid>
                )
            )}
          </Grid>
          
          </div>
          </div>        
    );
  }
}

export default withRouter(ChannelsHome);
