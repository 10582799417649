import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";

const ChannelsShimmer = (props) => {
    return (
        <div className="liveShimmer mobileScrollParent m-t-15">
            <Skeleton variant="text" width={'30%'} height={25} />
            <Skeleton variant="text" width={'40%'} height={15} />
            <div className="mobileScroll m-t-5">
                <Grid
                    container
                    justifyContent="flex-start"
                    spacing={4}
                    className="bg-white m-t-xs-5 flexNoWrap cardList"
                >
                    {[1, 2, 3].map((i) => (
                        <Grid key={i} item lg={4} md={6} sm={12} xs={12}>
                            <div>
                                <Grid container item className="align-left">
                                    <Grid item lg={3} md={3} xs={3}>
                                        <Skeleton variant="circle" width={80} height={80} />
                                    </Grid>
                                    <Grid item lg={9} md={9} xs={9} className="p-l-xs-15  v-center align-left align-middle">
                                        <Skeleton variant="text" className="pointer-cursor" width={'70%'} />
                                        <Skeleton variant="text" className="linkText" width={'50%'}>
                                            <Skeleton variant="text" className="makeTextClickable" width={'65%'} height={13} />
                                        </Skeleton>
                                    </Grid>
                                </Grid>
                                <Skeleton variant="text" width={'30%'} height={20} />
                                <Skeleton variant="text" width={'50%'} height={15} />
                                <div>
                                    <Skeleton variant="rect" className="Trailer thumbnail cardBorder" />
                                    <Skeleton variant="text" width={104} />
                                    <Skeleton variant="text" width={368} />
                                    <Skeleton variant="text" width={104} />
                                </div>
                            </div>
                        </Grid>
                    )
                    )}
                </Grid>

            </div>
        </div>
    );
}
export default ChannelsShimmer;
