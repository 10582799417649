import { useDispatch, useSelector } from "react-redux";
import { fetchAllClassesForChannel } from "../store/actions/channels";
import { SAVE_DEEPLINK_PAYLOAD } from "../store/actions/actionTypes";
const useChannelView = () => {
  const dispatch = useDispatch();
  const useChannelViewState = () => ({
    channelsTiltes: useSelector((state) => state.channels.classes),
    isClassesLoading: useSelector((state) => state.channels.isClassesLoading),
    isCalled: useSelector((state) => state.channels.classes === null && !state.channels.isClassesLoading && !state.channels.classLoadingFailed),
  });
  const useChannelViewDispatch = () => ({
    fetchAllClassesForChannel: (channelTag, pageSize, pageNumber) => {
      dispatch(fetchAllClassesForChannel(channelTag, pageSize, pageNumber));
    },
    saveDeeplinkPayload: (path) => {
      dispatch({ type: SAVE_DEEPLINK_PAYLOAD, deeplinkData: path });
    },
  });
  const initialState = {
    channelsData: null,
    titlesToDispay: null,
    hasMoreData: null,
    pageNumber: 1,
    isLoading: false,
    itemRemaining: 0,
    count:0
  };
  const useChannelActions = {
    UPDATE_CHANNEL: "UPDATE_CHANNEL",
  };
  const useChannelReducer = (state, action) => {
    const {type,payload} = action;
    switch (type) {
      case useChannelActions.UPDATE_CHANNEL:
        return {...state,...payload};
      default:
        return state
    }
  };
  const setReducerData = (channelDispatch,payload)=>{
    channelDispatch({
        type:useChannelActions.UPDATE_CHANNEL,
        payload:payload
    });
  }
    
  return {
    useChannelViewState,
    useChannelViewDispatch,
    channelReducer: { useChannelReducer, useChannelActions, initialState,setReducerData },
  };
};
export { useChannelView };