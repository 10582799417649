var Environment = getEnvironment();

function getEnvironment() {
    const PLATFORM = process.env.REACT_APP_PLATFORM;

    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "CFL") {
      platform = "test";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    } 
    const clientInfo = {
      "stage" : [
          { brand : "FF", country: 'ID', clientId: 'E3B71E2C-0EAF-4E34-AD6C-80201E565A36' },
          { brand : "CF", country: 'ID',clientId: '3EFA2BA1-FA8B-4497-A2FC-CD8234D051D0' }
      ],
      "production": [
          { brand : "FF", country: 'ID', clientId: '40B894BD-58E9-45DB-A368-9CDE7602D7AF' },
          { brand : "CF", country: 'ID',clientId: 'CDC4591E-9329-4627-B275-4842EA322F87' }
      ]
    }
    const SSO = {
        clientInfo:clientInfo[platform],
        OPServer: platform === 'stage' ? 'https://id.uat.circuithq.com/' : 'https://id.circuithq.com/',
        redirect_uri: '/callback',
        scope: 'openid profile member-data offline_access',
        userInfoEndpoint: 'connect/userinfo',
        extra: {prompt: 'consent', access_type: 'offline'}
    }
    var _Environments = {
        production: { SSO, BASE_URL: 'https://api.wexer.com/', API_KEY: 'a0ddf4dc5b0b4b628e5a4c27b93c988e', JWPLAYER:'95riWD5s.js',API_SECRET: '711ff01003764889987158b329c7e6c0' },
        stage: { SSO, BASE_URL: 'https://stage.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'wUkkXJdm.js', API_SECRET: '573572796441127398913495789868468206481' },
        development : { SSO, BASE_URL: 'https://ff-int.api.wexer.com/', API_KEY: '1234123412341234', JWPLAYER:'HofBP5zj.js',API_SECRET: '573572796441127398913495789868468206481' },
        test : { SSO, BASE_URL: "https://test.api.wexer.com/", API_KEY: '50703717552133197901571613251413029814', JWPLAYER:'HofBP5zj.js',API_SECRET: '51749401073929517144660310291806967949' },
        uat: { SSO, BASE_URL: 'https://uat.api.wexer.com/', API_KEY: '1234123412341234',  JWPLAYER:'wUkkXJdm.js',API_SECRET: '573572796441127398913495789868468206481' },
    
    }
    return _Environments[platform];
  }

module.exports = Environment 
 