import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";

const BrowseClassesShimmer = (props) => {

    return (
        <div className="browseShimmer mobileScroll m-t-15 m-t-xs-32 m-b-xs-10">
            <Skeleton variant="text" width={'30%'} height={25} />
            <Skeleton variant="text" width={'40%'} height={15} />
            <Grid item className="mobileScroll m-b-10">
                <Grid container direction="row" className="squareImageContainer m-t-5 " spacing={4}>
                    {[1, 2, 3, 4, 5, 6].map(() =>
                        <Grid item key={Math.random()} className="align-left">
                            <div className="imageContainer">
                                <Skeleton variant="rect" width={173} height={173} style={{background:"transparent"}} />
                                <div className="overlay" style={{ maxWidth: "173px" }}>
                                    <div className="bottomText text-white">
                                        <Skeleton variant="text" width={'50%'} height={13} className="m-b-10 m-b-xs-10" />
                                    </div>
                                </div>
                            </div>
                        </Grid>)}
                </Grid>
            </Grid>
        </div>
    );
}
export default BrowseClassesShimmer;
