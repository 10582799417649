import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";
import { IsMiniMobile,IsMobile,IsTab,IsDesktop,IsProjector } from '../../utils/breakPoints';

const LoadingClassesShimmer = ({totalCards=3,myClass=''}) => {
    const restCards = (items,division) => Number((items % division).toFixed());
    const handelItems = ()=>{
        try{
            let items = [];
            if(IsMiniMobile()){
                items.length = 1;
            }
            if(IsMobile()){
                items.length = 1;
            }
            if(IsTab()){
                const leftCards = restCards(totalCards,2);
                items.length = leftCards === 0 ? 2 : 1;
            }
            if(IsDesktop() ^ IsProjector()){
                const leftCards = restCards(totalCards,3);
                items.length = leftCards === 0 ? 3 : leftCards === 1 ? 2 : 1;
            }
            items.fill(1);
            return items;
        }
        catch(e){
            return [1,2,3]
        }
    }
    return (
        myClass === '' ? (
        <Grid item lg={12} xs={12} sm={12} md={12}>
            <div className="collectionShimmer m-t-30 m-b-xs-10">
                <Grid container justifyContent="flex-start" spacing={4} className="cardList flexNoWrap collectionShimmerCards">
                    {[1, 2, 3].map((item) =>
                        <Grid key={item} item lg={4} md={6} sm={12} xs={12} >
                            <Skeleton variant="rect" height={213} className="Trailer thumbnail cardBorder" />
                            <Skeleton variant="text" width={'30%'} height={20} />
                            <Skeleton variant="text" width={'50%'} height={15} />
                            <Skeleton variant="text" width={'30%'} height={17} />
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
        ):(
            <React.Fragment>
            {handelItems().map((val,i) =>
                <Grid key={i} className={myClass} item lg={4} md={6} sm={12} xs={12} >
                    <Skeleton variant="rect" className="Trailer thumbnail cardBorder" />
                    <Skeleton variant="text" width={'30%'} height={20} />
                    <Skeleton variant="text" width={'50%'} height={15} />
                    <Skeleton variant="text" width={'30%'} height={17} />
                </Grid>
            )}
        </React.Fragment>
        )
    );
}
export default LoadingClassesShimmer;
