import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import { useSelector } from "react-redux";
import ClubCardNew from './ClubCardNew'
import { Grid, Typography } from "@material-ui/core";
import { Translate } from '../../utils/Translate';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Routes from "../../components/Router/Routes"
import Workout from '../../hoc/WorkoutContainer';
const config = require("../../assets/config.json")


function ClubPage(props) {
    const isAuthenticated = useSelector(state => state.auth.token !== "")
    const viewDetails = () => {
        props.history.push({
            pathname: Routes.connectOndemandClasses,
            state: { clubClasses: props.clubClasses }
        });
    };

    const onClickHandler = data => {
        props.history.push({
            pathname: Routes.onDemandClassPlayerRoute + data.tag,
            state: { selectedItem: data },
        });
    };
    return (
        <Fragment>
            <div className="left-border m-t-15">
                <Grid container direction="row" alignItems="center" >
                    <Grid item className="align-left makeTextClickable" onClick={viewDetails}>
                        <Typography variant="h1" className="align-left">{Translate({ id: "homePage.ConnectTitle" })} </Typography>
                    </Grid>
                    <Grid item className="makeTextClickable" onClick={viewDetails}>
                        <KeyboardArrowRightIcon
                            className="align-bottom makeTextClickable iconColor"
                            style={{ fontSize: "2.7rem" }}
                            onClick={viewDetails}
                        />
                    </Grid>
                </Grid>
                <Typography className="text-gray align-left" variant="h6" >
                    {Translate({ id: "homePage.ConnectDescription" })}
                </Typography>
            </div>
            <div className="m-t-15 m-t-xs-15  m-b-20 mobileScroll">
                <Grid container justify="flex-start" spacing={4} className="cardList flexNoWrap livstreamHome">
                    {props.clubClasses.map((item, index) => (
                        index < 3 ?
                            <Grid item lg={4} md={6} sm={12} xs={12} key={item.id}>
                                {config.connectSource === 'wexer' ?
                                    <Workout
                                        //keywords={item.keywords}
                                        isSearchPageCard={false}
                                        creationDate={item.virtualClass.scheduleDate}
                                        trailerLinkWeb={item.virtualClass.trailerLinkWeb}
                                        trailerLinkMobile={item.virtualClass.trailerLinkMobile}
                                        isSliderWorkout={true}
                                        history={props.history}
                                        showFav={isAuthenticated ? true : false}
                                        thumbnail={`${item.virtualClass.imageLink}`}
                                        url={item.virtualClass.streamingLink}
                                        equipmentTypes={item.virtualClass.equipmentTypes}
                                        equipmentNames={item.virtualClass.equipmentNames}
                                        isFav={item.virtualClass.favourite}
                                        title={item.virtualClass.className}
                                        duration={item.virtualClass.durationSecond
                                            ? Math.floor(item.virtualClass.durationSecond / 60)
                                            : 55}
                                        description={item.virtualClass.classDescription}
                                        show={true}
                                        calories={item.virtualClass.calorieBurn}
                                        id={item.virtualClass.tag}
                                        classCategory={item.virtualClass.classCategory}
                                        // key={categoryId + index}
                                        clicked={() => onClickHandler(item.virtualClass)}
                                        level={item.virtualClass?.level ? item.virtualClass.level : item.level }
                                        // collectionName={categoryId}
                                        // favCallback={() => { checkFavStatus(item.virtualClass.tag) }}
                                    /> :
                                    <ClubCardNew clubData={item}></ClubCardNew>
                                }

                            </Grid>
                            : null
                    ))}
                </Grid>
            </div>
        </Fragment>
    )
}

export default withRouter(ClubPage)

