import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from './components/spinner/AppLoader';
import { BrowserRouter } from "react-router-dom";
import Localytics from "./hoc/LocalyticsContainer";
import { fetchTenantConfig } from './store/actions/ondemand'
import App from './App';
import EmededWrapper from './EmbeddedWrapper'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from "./theme/theme";
import { setAppLoadStartEvent } from "./store/actions/webPlayer";

class AppWrapper extends Component {
    componentDidMount(){
        this.props.webPlayerStartTime(this.props.appLoadStartTime)
    }
    render() {
        return (
            <React.Fragment>
                {this.props.tenantConfig == null ?
                    <Spinner fetchTenantConfig={this.props.fetchTenantConfig} /> :
                    <Localytics>
                        <BrowserRouter>
                            <MuiThemeProvider theme={theme}>

                                <EmededWrapper embededConfig={this.props.embededConfig}>
                                    <App />
                                </EmededWrapper>
                            </MuiThemeProvider>

                        </BrowserRouter>
                    </Localytics>
                }

            </React.Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {

        tenantConfig: state.onDemand.tenantConfig

    };
};

const mapDispatchToProps = dispatch => {
    return {
        webPlayerStartTime:(appLoadStartTime)=>dispatch(setAppLoadStartEvent(appLoadStartTime)),
        fetchTenantConfig: () => dispatch(fetchTenantConfig()),

    };
};

//export default App;
export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AppWrapper);


