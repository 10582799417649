import * as actionTypes from "./actionTypes";
import api from "../../api";
import * as APIConstants from "../APIConstants";

export const fetchAllChannels = () => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_ALLCHANNELS_START });
    let userId = localStorage.getItem('userId');
    let url = userId ? APIConstants.getAllChannels + userId : APIConstants.getAllChannels;
    api.get(url)
      .then(response => {
        if (response.ok) {
          /// Filter out data having active channels and titles
          let filteredData = response.data.filter(obj => {
            let isAvailableForWebAndApp = false
            if (obj.availability && obj.availability.length > 0 && obj.availability.includes(1)) {
              isAvailableForWebAndApp = true
            }

            let isActive = obj.active
            let haveTitles = false
            if (obj.title && obj.title.length > 0) {
              haveTitles = true
            }

            return isActive && isAvailableForWebAndApp && haveTitles
          })
          dispatch({
            type: actionTypes.FETCH_ALLCHANNELS_SUCCESS,
            channels: filteredData
          });
        } else {
          dispatch({ type: actionTypes.FETCH_ALLCHANNELS_FAIL });
        }
      });
  };
};

/// Fetch all classes of given channel
export const fetchAllClassesForChannel = (channelTag, numberOfTitles, pageNumber, userId = null) => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_START });
    let userId = localStorage.getItem('userId');
    let url = userId ? `${APIConstants.getAllChannels}/${channelTag}/${numberOfTitles}/${pageNumber}/${userId}` : `${APIConstants.getAllChannels}/${channelTag}/${numberOfTitles}/${pageNumber}`
    api.get(url)
      .then(response => {
        if (response.ok) {
          dispatch({
            type: actionTypes.FETCH_CLASSESFORCHANNEL_SUCCESS,
            classes: response.data
          });
        } else {
          dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_FAIL });
        }
      });
  };
};


export const fetchDeeplinkedClassForChannel = (id, userId) => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_START });

    let userId = localStorage.getItem('userId');
    let url = userId ? `${APIConstants.getDeeplinkedChannelClass}/${id}/${userId}` : `${APIConstants.getDeeplinkedChannelClass}/${id}`
    api.get(url)
      .then(response => {
        if (response.ok) {
          dispatch({
            type: actionTypes.FETCH_CLASSESFORCHANNEL_SUCCESS,
            classes: response.data
          });
        } else {
          dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_FAIL });
        }
      });
  };
};