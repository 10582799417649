export const CLASS_LOG_TIMEOUT = 59; // RR Only after this time Leave PopUp will be shown on Player.

export const workoutResultType = {
    UnTracked: 0,
    DidNotWorkout: 1,
    Workout: 2,
    WorkoutResult: 3,
    Class: 4,
    Challenge: 5,
    PremiumPlan: 6,
    VirtualClass: 7,
    ClubConnectVideo: 8,
    LiveStream: 9,
    Channel: 10/// add channel type    
}

export const SubscriptionProductType = {
    Annual: "Annually",
    Quarterly: "Quarterly",
    Monthly: "Monthly",
    Trial: "trial", // don't change the value, it will impact the trial period.(src\store\selectors\subscription.js)
    Daily: "Daily"

}

export const IntensityValueText = {
    VerySlight:"VerySlight",
            Slight:"Slight",
            Moderate:"Moderate",
            SomewhatHard:"SomewhatHard",
            Hard:"Hard",
            VeryHard: "VeryHard",
            VeryVeryHard:"VeryVeryHard",
            Maximal:"Maximal"
}
export const SkillLevelText = {
    Beginner:"Beginner",
            Intermediate:"Intermediate",
            Advanced:"Advanced"
}

export const StripeSourceType = {
    Card: "card"
}

export const ConsentType = {
    TNC: "tnc",
    Marketing: "mktg"
}

export const ConsentAction = {
    Rejected: 1,
    Accepted: 2
}
export const SubscriberStatusConstants = {
    INACTIVE: 0,
    ACTIVE: 1,
    UNKNOWN: 2
}
export const isMobileOrTab = () =>{
    try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    catch(e){
        return false
    }
}
export const isIPAD = () => {
    try {
        let isiPad = navigator.userAgent.match(/iPad/i) != null;
        return isiPad
    } catch (e) {
        return false;
    }
};
export const LiveStreamStatusConstants = {
    UNAVAILABLE: "unavailable",
    STREAMING: "streaming",
    AWAITING: "awaiting"
}

export const SpinnerConstants = {
    SIZE: 70,
    THICKNESS: 5
}

export const LAZY_LOADING_PAGE_SIZE = 18;

export const SSO_TOKEN_EXPIRE_BUFFER_SEC = 10;