import React from "react";
import { withRouter } from 'react-router'   
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate"
import Workout from "../../hoc/WorkoutContainer"  

function ChannelsCard(props) { 
    
    const checkFavStatus = () => {
        let classes = props.data.title;
        classes[0].favourite = !classes[0].favourite
    }
  
    return (
        <div className="channelHomeContainer">
            <Grid container item className="align-left">
                <Grid item lg={3} md={3} xs={3}>
                    <img
                    alt="chanel-img"
                    onClick={props.redirectTo}
                    src={props.profileImageUrl} 
                    className="channel-profileImg"
                    />                                    
                </Grid>  
                <Grid item lg={9} md={9} xs={9} className="p-l-xs-15  v-center align-left align-middle">
                            <Typography variant="subtitle2" className="pointer-cursor"  onClick={props.redirectTo}>
                                {props.displayName}
                            </Typography>
                            
                            <Typography variant="h4" className="linkText">
                                <span className="makeTextClickable" onClick={props.redirectTo}>
                                    {Translate({id:"Channels.ViewChannel"})}
                                    </span>
                            </Typography>
                        
                </Grid>
            </Grid>  
            <Grid container item  className="align-left" style={{marginTop:"0px"}}>
                <Grid item>
                    <div>
                    <Typography variant="h4"
                    className="text-gray m-t-15 m-t-xs-15 channel-desc align-left">
                    {props.description}
                </Typography>
                </div>
                    <div>
                    <Typography variant="h4" className="m-t-15 m-t-xs-15 channels-subhead text-gray">
                        {Translate({
                            id:"Channels.ChannelLatest"
                        })}
                </Typography> 
                </div> 
                </Grid>
            </Grid>
            <div>
            <Workout
                creationDate={props.created_Time}
                trailerLinkWeb={props.trailerLinkWeb}
                // history={channelData.data.history}
                // showFav={isAuthenticated ? true : false}
                thumbnail={props.thumbnail}
                title={props.title}
                duration={props.duration
                    ? Math.floor(props.duration / 60)
                    : 55}
                description={props.streamDescription}
                show={true}
                id={props.id}
                trailerLinkMobile={props.trailerLinkMobile}
                url={props.streamingLink}
                clicked={props.gotoPlayer}
                level={props.level}
                isFav={props.isFav}
                collectionName={props.collectionName}
                favCallback={() => { checkFavStatus() }}
            />
        </div>
        </div>
    )
}

export default withRouter(ChannelsCard)